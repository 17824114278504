import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate, useNavigate,useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import Card from "@mui/material/Card";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import DownloadIcon from '@mui/icons-material/Download';

const ManagerCollection = () => {
  const navigate = useNavigate();
  const { sales, tasks } = reportsLineChartData;
  const empId = secureLocalStorage.getItem('emp_id');
  const [loading, setLoading] = useState(true);
  const [product, setproduct] = useState('');
  const [productlist,setproductlist] = useState([]);
  const [servicelist, setservicelist] = useState([]);
  const [service, setservice] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [emp_list, getemp_list] = useState([]);
  const [selectedmanagers, setselectedmanagers] = useState();
  const [sales_data, getsales_data] = useState({});
  const [managersteam,getmanagersteam] = useState([]);
  const [selectedemp,setselectedemp] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const [followup_status,get_followup_details] = useState([]);
  const {details} = useParams();
  const [inner_page_details,get_inner_page_details]  = useState([]);
  const [sum,get_sum] = useState();
  const [manager_team,get_manager_team] = useState([]);
  const[checkmanager,setcheckmanager] = useState('');
  const [grouplist, setgrouplist] = useState([]);
  const [group, setgroup] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;
  //const [category_list,set_category_list] = useState([]);
  const [category,selectedcategory] = useState('');
  const [team_collection,get_team_collection] = useState([]);
  const [showTeamCollection, setShowTeamCollection] = useState(false);
  useEffect(() => {
    productdetails();
   // fetchdata();
    get_emp_list();
     get_sales_manager_team();
     groupdetails();
  }, []);

  useEffect(() => {
  sales_inner_page_details(currentPage);
}, [currentPage, product, end_date, start_date, service, selectedmanagers,category, selectedemp, group]);


   useEffect(() => {
    sales_inner_page_filter(currentPage);
  }, [currentPage,product, end_date, start_date, service,category,  selectedmanagers,selectedemp,group,currentPage]);

  
  const toggleTeamCollection = () => {
        setShowTeamCollection(prev => !prev);
    };

   const downloadata = async () => {
          //emp_id: empId,

    try {
      const response = await dashboardApi.get('sales-inner-page-details', {
       params: {
         download: 'csv',
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          service: service,
          category:category,
          manager:selectedmanagers,
          employee:selectedemp,
          type:details,
          group:group,
        },
       responseType: 'blob',
    });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `sales_data_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  };

  const get_sales_manager_team = async()=>{
     try {
      const endpoint = `sales-dashboard-show-manager-team/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        get_manager_team(response.data.data);
        setcheckmanager(response.data.reporting_manager);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  const groupdetails = async () => {
    try {
      const endpoint = `get-group-based-on-sales-emp/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


   useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);

  const get_emp_list = async () => {
    try {
      const endpoint = 'get-sales-managers';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getemp_list(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  useEffect(() => {
    if (selectedmanagers) {
      setselectedemp([]);

      const fetchData = async () => {
        try {
          const endpoint = `get-all-manager-employee/${selectedmanagers}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            getmanagersteam(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedmanagers]);


  const sales_inner_page_details = async (page = 0) => {
  setLoading(true);
  try {
    const response = await dashboardApi.get('get-manager-wise-collection', {
      params: {
        emp_id: empId,
        start_date: start_date,
        end_date: end_date,
        product: product,
        service: service,
        category:category,
        manager: selectedmanagers,
        employee: selectedemp,
        type: details,
        group: group,
      },
    });
    if (response.data.status === 200) {
        get_inner_page_details(response.data.data);
        //setTotalPages(response.data.last_page || 1);
    } else {
      console.error('Error fetching data:', response.data.error);
    }
    setLoading(false);
  } catch (error) {
    console.error('Error fetching data:', error);
    setLoading(false);
  }
};

  const sales_inner_page_filter = async () => {
    //setLoading(true)
    try {
      const response = await dashboardApi.get('get-manager-wise-collection', {
        params: {
        emp_id: empId,
        start_date: start_date,
        end_date: end_date,
        product: product,
        service: service,
        manager: selectedmanagers,
        employee: selectedemp,
        type: details,
        group: group,
      },
    });
      if (response.data.status === 200) {
        get_inner_page_details(response.data.data);
        //setTotalPages(response.data.data.last_page || 1);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      //setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };



  const view_team = async(emp_id)=>{
    toggleTeamCollection();
    try {
        const response = await dashboardApi.get('get-team-collection-details', {
          params: {
          emp_id: emp_id,
          start_date: start_date,
          end_date: end_date,
          product: product,
          service: service,
          manager: selectedmanagers,
          employee: selectedemp,
          type: details,
          group: group,
        },
      });
        if (response.data.status === 200) {
            get_team_collection(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }



  }
  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  const toggleDetails = (empId) => {
    //alert(empId);
    const detailsRow = document.getElementById(`details-${empId}`);
    detailsRow.style.display = detailsRow.style.display === 'table-row' ? 'none' : 'table-row';
};

const download_manager_collection = async (manager_id,manager_name,collectiontype)=>{
 try {
  //alert(manager_name);
      const response = await dashboardApi.get('download-manager-collection', {
       params: {
         //download: 'csv',
         manager:manager_id,
         start_date: start_date,
         end_date: end_date,
         product: product,
         group: group,
         type:collectiontype,
        },
       responseType: 'blob',
    });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `${manager_name}_collection_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }

}




const get_category_details = async()=>{
  try {
    const response = await dashboardApi.get('count-active-packages-with-category', {
      params: {
      emp_id: id,
      start_date: start_date,
      end_date: end_date,
      product: product,
      service: service,
    },
  });
    if (response.data.status === 200) {
      get_category_list(response.data.data);
      set_count(response.data.count);
    } else {
      console.error('Error fetching data:', response.data.error);
    }
    setLoading(false);
  } catch (error) {
    console.error('Error fetching data:', error);
    setLoading(false);
  }



}

return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <div>
          <Link to='/sales-dashboard' className='btn btn-info btn-sm'>Back</Link>
            <div className="d-flex flex-wrap">
              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={group}
                  onChange={(e) => setgroup(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Group</option>
                  {grouplist.map((row) => (
                    <option key={row.group_id} value={row.group_id}>
                      {row.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={product}
                  onChange={(e) => setproduct(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Product</option>
                  {productlist.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.product_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={start_date}
                  onChange={(e) => setstart_date(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={end_date}
                  onChange={(e) => setend_date(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>

            </div>
          </div>

          <div className="card-body">
           
              <div className="table-responsive">
               <table className='table table-bordered table-stripted' style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td >New</td>
                        <td >Renew</td>
                        <td >Due</td>
                        <td >Retention</td>
                        <td >Total Collection</td>
                        <td>App Collection</td>
                        <td>App Packages</td>
                        <td>Active Packages</td>
                        <td >Details</td>
                    </tr>
                </thead>
                <tbody>
                    {inner_page_details.map(manager => (
                        <React.Fragment key={manager.manager_id}>
                            <tr>
                                <td>{manager.manager_name}</td>
                                <td >{manager.total.total_new}</td>
                                <td >{manager.total.total_renew}</td>
                                <td >{manager.total.total_due}</td>
                                <td >{manager.total.total_retention}</td>
                                <td >{manager.total.total_paid}</td>
                                <td>{manager.total.total_app_amount}</td>
                                <td>{manager.total.total_app_packages}</td>
                                <td>{manager.total.active_packages}</td>
                                <td style={{minWidth:'350px'}}>
                                    <button  className='btn btn-info btn-sm'
                                        onClick={() => {
                                            const detailsRow = document.getElementById(`details-${manager.manager_id}`);
                                            detailsRow.style.display = detailsRow.style.display === 'table-row' ? 'none' : 'table-row';
                                        }}
                                    >
                                    Details
                          
                                    </button>

                                    &nbsp; 
                                    <button className='btn btn-success btn-sm'onClick={() => view_team(manager.manager_id)} >Team</button>
                                    
                                     &nbsp; 
                                     <Link target='_blank' to='/packages-details'  className='btn btn-success btn-sm'>Packages</Link>
                                    
                                    &nbsp; 

                                    <Link className='btn btn-success btn-sm' to={`/sales-description/${encodeURIComponent('totalCollection')}`}>List</Link>
                                    &nbsp;
                                    <button className="btn btn-primary btn-sm" onClick={() => download_manager_collection(manager.manager_id,manager.manager_name,'managercollection')}>
                                    <span class="material-icons-outlined">
                                     file_download
                                    </span>
                                  </button>


                                </td>
                            </tr>
                            <tr id={`details-${manager.manager_id}`} style={{ display: 'none' }}>
                                <td colSpan={7}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <thead>
                                            <tr>
                                                <th>Manager</th>
                                                <th>Group</th>
                                                <th>Product</th>
                                                <th>New</th>
                                                <th>Renew</th>
                                                <th>Due</th>
                                                <th>Retention</th>
                                                <th>Total Collection</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {manager.details.map(detail => (
                                                <tr key={detail.group_id}>
                                                    <td>{manager.manager_name}</td>
                                                    <td>{detail.group_name}</td>
                                                    <td>{detail.product_name}</td>
                                                    <td>{detail.total_new}</td>
                                                    <td>{detail.total_renew}</td>
                                                    <td>{detail.total_due}</td>
                                                    <td>{detail.total_retention}</td>
                                                    <td>{detail.total_paid}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
              </div>
          </div>
          {showTeamCollection && (
            <>
           <center style={{color:'red'}}>View Team Collection</center>
           <div className="card-body">
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="table-responsive">
                    <table className='table table-bordered table-stripted' style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                        <thead>
                            <tr>
                                <td>Employee Name</td>
                                <td>Manager</td>
                                <td> New</td>
                                <td> Renew</td>
                                <td> Due</td>
                                <td> Retention</td>
                                <td> Total Collection</td>
                                <td>Active Packages</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {team_collection.map(employee => (
                                <React.Fragment key={employee.emp_id}>
                                    <tr>
                                        <td>{employee.emp_name}</td>
                                        <td>{employee.manager}</td>
                                        <td>{employee.total_new}</td>
                                        <td>{employee.total_renew}</td>
                                        <td>{employee.total_due}</td>
                                        <td>{employee.total_retention}</td>
                                        <td>{employee.total_paid}</td>
                                        <td>{employee.active_package}</td>
                                        <td style={{minWidth:'280px'}}>
                                            <button className='btn btn-info btn-sm' onClick={() => toggleDetails(employee.emp_id)}>
                                                Details
                                            </button>
                                            &nbsp;
                                            <Link className='btn btn-info btn-sm' to={`/sales-description/${encodeURIComponent('totalCollection')}`}>List</Link>
                                            &nbsp;

                                          <Link target='_blank' to='/packages-details' className='btn btn-success btn-sm'>Packages</Link>
                                          &nbsp;
                                     <button 
                                        className="btn btn-primary btn-sm" 
                                         onClick={() => download_manager_collection(employee.emp_id, employee.emp_name,'empcollection')}
                                         >
                                      <span className="material-icons-outlined">
                                        file_download
                                      </span>
                                       </button>

                                             </td>

                                    </tr>
                                    <tr id={`details-${employee.emp_id}`} style={{ display: 'none' }}>
                                        <td colSpan={7}>
                                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Emp</th>
                                                        <th>Manager</th>
                                                        <th>Group</th>
                                                        <th>Product</th>
                                                        <th>New</th>
                                                        <th>Renew</th>
                                                        <th>Due</th>
                                                        <th>Retention</th>
                                                        <th>Total Collection</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {employee.details.map(detail => (
                                                        <tr key={detail.group}>
                                                            <td>{detail.emp_name}</td>
                                                            <td>{detail.manager_name}</td>
                                                            <td>{detail.group}</td>
                                                            <td>{detail.product}</td>
                                                            <td>{detail.new}</td>
                                                            <td>{detail.renew}</td>
                                                            <td>{detail.due}</td>
                                                            <td>{detail.retention}</td>
                                                            <td>{detail.total_sum}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
        </>
        )}
        <br/>
       

          
        </Card>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default ManagerCollection;
