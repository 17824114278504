import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import dashboardApi from "layouts/common_components/apibase_url";
import "./emp-leave.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import secureLocalStorage from "react-secure-storage";

function LeaveRequestHrDashboard() {
  const links = document.querySelectorAll('#btn');
  console.log(links);

  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const navigate = useNavigate();

  const [validationErrors, setValidationErrors] = useState({});
  const [leaveType, setLeaveType] = useState("");
  //const [remark, setRemark] = useState("");
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [status,setstatus] = useState('');
  const [employee_id,set_employee_id] = useState('');
  const [id,set_id] = useState('');

  // Redirect if token is missing
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(()=>{
    fetchLeaveRequests()

  },[emp_id])

  // Fetch leave types
  useEffect(() => {
    const fetchLeaveTypes = async () => {
      try {
        const response = await dashboardApi.get(`show-leave-type-based-on-emp-status/${emp_id}`);
        if (response.data.status === 200) {
          setLeaveTypes(response.data.data);
        } else {
          console.error("Error fetching leave types:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    };
    fetchLeaveTypes();
  }, [emp_id]);

  // Fetch leave requests
    const fetchLeaveRequests = async () => {
      try {
        const response = await dashboardApi.get('leave-request-details-hr-dashboard');
        if (response.data.status === 200) {
          setLeaveRequests(response.data.data);
          setLoading(false);
        } else {
          console.error("Error fetching leave requests:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leave requests:", error);
      }
    };

    const modal_open = (employee_id,id)=>{
      set_employee_id(employee_id);
      set_id(id);
      setOpenRequestModal(true);

    }
  

  const handleCloseModal = () => {
    setstatus("");
    set_id("");
    set_employee_id('')
    setValidationErrors({});
    setOpenRequestModal(false);
  };

  const handleSaveData = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!status) errors.status = "Status is required";
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const formData = new FormData();
        formData.append("status", status);
        formData.append("action_by",emp_id);
        formData.append("emp_id", employee_id);
        formData.append("id",id);


        const response = await dashboardApi.post("action-on-emp-leave-request", formData);

        if (response.data.status === 200) {
          Swal.fire({ icon: "success", text: response.data.message });
          handleCloseModal();
          fetchLeaveRequests();
        } else {
          Swal.fire({ icon: "error", text: response.data.message });
        }
      } catch (error) {
        Swal.fire({ icon: "error", text: "An error occurred" });
        console.error("Error saving leave request:", error);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="col-md-4">
              <Link to='/hr-dashboard' className="btn btn-info btn-sm">Back</Link>

              </div>
              <div className="container-fluid">
              </div>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Emp Name</td>
                        <td>Department</td>
                        <td>Leave Name</td>
                        <td>Remark</td>
                        <td>Status</td>
                        <td>Action By</td>
                        <td>Request Date</td>
                        <td>Action</td>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {leaveRequests.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>
                            {row.emp_fname} {row.emp_lame}
                          </td>
                          <td>{row.department_name}</td>
                          <td>{row.leave_name}</td>
                          <td>{row.remark}</td>
                          <td>{row.status_text}</td>
                          <td>
                            {row.approved_by_fname} {row.approved_by_lame}
                          </td>
                          <td>{row.created_date}</td>
                          {
                            row.emp_id != emp_id &&
                            <td style={{minWidth:'200px'}}>
                            <button onClick={() => modal_open(row.emp_id,row.id)} className="btn btn-info btn-sm">Action</button>
                            &nbsp; &nbsp;
                            <Link to={`/leave-permission/${row.emp_id}`} className='btn btn-success me-2 btn-sm'>
                             Assign Leave
                            </Link>

                          </td>
                          }
                         
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>

            <Dialog
              open={openRequestModal}
              fullWidth
              PaperProps={{ style: { maxWidth: "600px", padding: "20px" } }}
            >
              <DialogTitle>Action On Request</DialogTitle>
              <DialogContent>
                <form>
                  <div className="form-group">
                    <label htmlFor="leaveType">Select Status</label>
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setstatus(e.target.value)}
                    >
                      <option value="">Select Leave Type</option>
                      <option value='1'>Approved</option> 
                      <option value='2'>Rejected</option>
                    </select>
                    {validationErrors.status && (
                      <div className="text-danger">{validationErrors.status}</div>
                    )}
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <button className="btn btn-danger" onClick={handleCloseModal}>
                  Close
                </button>
                <button className="btn btn-info" onClick={handleSaveData}>
                  Save
                </button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LeaveRequestHrDashboard;
