import React, { useState, useEffect, useRef } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import secureLocalStorage from "react-secure-storage";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import 'layouts/sales/moving/followupStyle.css';

function ExpireClient() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientDetails, setClientDetails] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);

  const [selectedMobileNo, setClientMobileNumber] = useState();
  const [selectedCompanyName, setClientCompanyName] = useState();
  const [groupDetails, setGroupDetails] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const isInitialMount = useRef(false);
  const [selectedClientType,setClientType]=useState("recent");
  const [recentStatus, setRecentStatus] = useState("");
  const [monthStatus, setMonthStatus] = useState("");
  const [moreThanMonthStatus, setMorethanMonthStatus] = useState("");
  const [expireClientCount, setExpireClientCount] = useState({});
  
  useEffect(() => {
    getExeDetails();
    getGroupDetails();
    setActiveStatus('recent');
  }, []);

  // useEffect(() => {
  //   if(isInitialMount.current)
  //   {
  //     isInitialMount.current = false;
  //     return;
  //   }
  //   getClientDetails();
  // }, [currentPage])

  const getClientDetails = async (filters = {}) => {
    getExpireClientCount();
    let employeeId = '';
    try {
      const {
        emp_id: employeeId,
        mobileNo: selectedMobileNo = '',
        companyName: selectedCompanyName = '',
        selectedGroup: selectedGroup = '',
        per_page: postPerPageValue = postPerPage,
        page: currentPageValue = currentPage + 1,
      } = filters;

      const response = await dashboardApi.get('get-expire-client-details', {
        params: {
          emp_id: selectedEmp,
          mobileNo: selectedMobileNo,
          companyName: selectedCompanyName,
          selectedGroup: selectedGroup,
          selectedClientType : selectedClientType,
          per_page: postPerPageValue,
          page: currentPageValue,
        },
      });

      if(response.data.status === 200)
      {
        const responseData = response.data.data;
        const clientData = responseData.data || responseData;
        setClientDetails(clientData);
        setNumber(responseData.last_page);
      }
      else
      {
        console.error('Error fetching client details:', response.data.message);
      }
      setLoading(false);
    }
    catch (error)
    {
      console.error('Error fetching client details:', error);
      setLoading(false);
    }
  };

  useEffect(() =>
  {
    if(isInitialMount.current)
    {
      isInitialMount.current = false;
      return;
    }

    const filters = {
      emp_id: selectedEmp,
      mobileNo: selectedMobileNo,
      companyName: selectedCompanyName,
      selectedGroup: selectedGroup,
      per_page: postPerPage,
      page: currentPage + 1,
    };

    getClientDetails(filters);
  }, [selectedEmp, selectedMobileNo, selectedCompanyName, selectedGroup, currentPage,selectedClientType]);

  const getExpireClientCount = async () => {
    try {
      const response = await dashboardApi.get(`get-expire-client-count`, {
        params: {
          emp_id: selectedEmp,
          selectedGroup: selectedGroup,
        }
      });
      if (response.data.status === 200) {
        setExpireClientCount(response.data.data);

      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  const getGroupDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-group-list`, {
        params: {
          emp_id: emp_id,
        }
      });
      if (response.data.status === 200) {
        setGroupDetails(response.data.data);

      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }






  const getExeDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
      if (response.data.status === 200) {
        setExeDetails(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  const setActiveStatus = (clientType) => {
    setClientType(clientType);
    if (clientType === "recent") {
      setRecentStatus("active");
      setMonthStatus("");
      setMorethanMonthStatus("");
    } else if (clientType === "month") {
      setRecentStatus("");
      setMonthStatus("active");
      setMorethanMonthStatus("");
    } else if (clientType === "abovemonth") {
      setRecentStatus("");
      setMonthStatus("");
      setMorethanMonthStatus("active");
    } 
    else
    {
      setRecentStatus("active");
      setMonthStatus("");
      setMorethanMonthStatus("");
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Card>
          <div className="card-body p-2">
            <div className="d-flex justify-content-between align-items-center">
              <ClientDetailsHeader></ClientDetailsHeader>
            </div>
            <div className="col-sm-12 d-flex" style={{overflowY: "hidden"}}>
              <div className="p-1">
                <button
                  className={`btn btn-primary small tabss ${recentStatus}`}
                  onClick={() => {
                    setActiveStatus("recent");
                    setCurrentPage(0);
                  }}
                  style={{ width: "max-content" }}
                >
                  Recent Expire Client
                  <small
                    className="position-relative bg-danger px-1 text-white rounded"
                    style={{ left: "6px", bottom: "6px" }}
                  >
                  {expireClientCount.recent_expire}
                  </small>
                </button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${monthStatus}`} onClick={() => {
                  setActiveStatus("month");
                  setCurrentPage(0);
                
                }} style={{width: "max-content"}}>Monthly Expire Client 
                  <small className="position-relative bg-danger px-1 text-white rounded" style={{left:"6px",bottom:"6px"}}>{expireClientCount.month_expire}</small>
                </button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${moreThanMonthStatus}`} onClick={() => {
                  setActiveStatus("abovemonth");
                  setCurrentPage(0);
                }} style={{width: "max-content"}}>More Than Month Expire Client<small className="position-relative bg-danger px-1 text-white rounded" style={{left:"6px",bottom:"6px"}}>{expireClientCount.over_month_expire}</small></button>
              </div>
            </div>

            <div className='d-flex flex-wrap px-sm-0 px-2'>
              <div className='col-sm-3 col-12 px-sm-2 py-2 px-0'>
                <small>Mobile No</small>
                <input type="text" className='form-control' value={selectedMobileNo} onChange={(e) => { setClientMobileNumber(e.target.value);setCurrentPage(0) }} />
              </div>
              <div className='col-sm-3 col-12 px-sm-2 py-2 px-0'>
                <small>Company Name</small>
                <input type="text" className='form-control' value={selectedCompanyName} onChange={(e) => {setClientCompanyName(e.target.value);setCurrentPage(0) }} />
              </div>
              <div className='col-sm-3 col-12 px-sm-2 py-2 px-0'>
                <small>Select Eexecutive</small>
                <select className="form-control"
                  value={selectedEmp}
                  onChange={(e) => {
                    setSelectedEmp(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value=''>Select Eexecutive</option>
                  {exeDetails.map((ed) => (
                    <option key={ed.emp_id} value={ed.emp_id}>
                      {ed.emp_fname} {ed.emp_lname}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-sm-3 col-12 px-sm-2 py-2 px-0'>
                <small>Select Group</small>
                <select className="form-control"
                  value={selectedGroup}
                  onChange={(e) => {
                    setSelectedGroup(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value=''>Select Group</option>
                  {groupDetails && groupDetails.map((gd) => (
                    <option key={gd.group_id} value={gd.group_id}>
                      {gd.name}
                    </option>
                  ))}
                </select>
              </div>

            </div>


            {loading ? (
              <p style={{ align: 'center' }}>
                <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
              </p>
            ) : (
              <div className='p-2' style={{ overflowY: "hidden" }}>
                <table className="table table-bordered table-hovered" style={{ marginTop: '20px' }}>

                  <tr>
                    <td>S.No</td>
                    <td>Client Info</td>
                    <td>Company Info</td>
                    <td>Group Name</td>
                    <td>City Name</td>
                    <td>Status</td>
                    <td>Executive</td>
                    <td>Action </td>
                  </tr>

                  {clientDetails && clientDetails.map((cd, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{cd.client_name}<br />{cd.mobile_no} <br /> {cd.email}<br />{cd.client_status == 1 ? 'Veryfied' : cd.client_status == 2 ? 'Active' : cd.client_status == 3 ? 'Inactive' : cd.client_status == 4 ? 'Expire' : cd.client_status == 5 ? 'BlackList' : cd.client_status == 6 ? 'isDeleted' : ''}</td>
                      <td>{cd.business_name}<br />{cd.comp_mobile_no} <br /> {cd.comp_email}
                        <br />{cd.status == 1 ? 'Veryfied' : cd.status == 2 ? 'Active' : cd.status == 3 ? 'Inactive' : cd.status == 4 ? 'Expire' : cd.status == 5 ? 'BlackList' : cd.status == 6 ? 'isDeleted' : ''}
                      </td>
                      <td>{cd.group_name}</td>
                      <td>{(cd.city_name != '' && cd.city_name != null) ? cd.city_name : cd.city}</td>
                      <td>{cd.status === 4 ? 'Expire' : ''}</td>
                      <td>{cd.emp_name}</td>
                      <td>
                        <Link className="btn btn-primary mb-2 float-end" to={`/mature-client-profile/${cd.client_id}`}>
                          <span class="material-icons-outlined" alt='Profile'>manage_accounts</span>
                        </Link>
                      </td>
                    </tr>
                  ))
                  }
                </table>
                <Pagination className="custom-pagination">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={number}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link-style"}
                    breakLinkClassName={"break-link-style"}
                    previousLinkClassName={"prev-link-style"}
                    nextLinkClassName={"next-link-style"}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </Card>
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}
export default ExpireClient;
