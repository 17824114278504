import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import ProfileInfoCard from "theme_layout/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "theme_layout/Lists/ProfilesList";
import DefaultProjectCard from "theme_layout/Cards/ProjectCards/DefaultProjectCard";
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import dashboardApi from "layouts/common_components/apibase_url";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.css';
import Popup from "layouts/profile/components/popup.js";
import secureLocalStorage from "react-secure-storage";

function Overview() {
  const token = secureLocalStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [profiledata, getprofiledata] = useState({});
  const [attandence, getattandence] = useState([]);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [searchdata, setSearchData] = useState('');
  const [leave, getLeave] = useState([]);
  const [workingday, setWorkingDay] = useState([]);
  const [leaveTakingData, setLeaveTakingData] = useState([]);
  const [wfh, getWfh] = useState([]);
  const [roster, setRoster] = useState([]);
  const [hoveredRowData, setHoveredRowData] = useState(null);
  const department_name = secureLocalStorage.getItem('department');
  const branch = secureLocalStorage.getItem("branch");
  const { id } = useParams();
  const emp_id = id ? id : secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState('');
  const [years, setYears] = useState([]);
  const [selectedmonth,setselectedmonth] = useState('');

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const pastYears = Array.from({ length: 6 }, (_, i) => currentYear - i);
    setYears(pastYears);
  }, []);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `view-daily-attendance/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.data.status === 200) {
          getattandence(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [emp_id]);

  useEffect(() => {
    const fetchLeaveTakingData = async () => {
      try {
        const endpoint = `emp-leave-taken-list/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.status === 200) {
          setLeaveTakingData(response.data.leave_entries);
        } else {
          console.error("Error fetching leave taking data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leave taking data:", error);
      }
    };
    fetchLeaveTakingData();
  }, [emp_id]);

   //const[profiledata,getprofiledata] = useState({});
    useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await dashboardApi.get(`emp-profile/${emp_id}`);
        if (response.data.status === 200) {
          getprofiledata(response.data.data[0]); // Assuming the API returns an array with a single object
        } else {
          console.error('Error fetching employee profile:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching employee profile:', error);
      } finally {
        //setLoading(false);
      }
    };

    fetchProfileData();
  }, [emp_id]);

  useEffect(() => {
    const fetchRoster = async () => {
      try {
        const endpoint = `roster-attendance/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.status === 200) {
          setRoster(response.data.data);
        } else {
          console.error("Error fetching roster data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching roster data:", error);
      }
    };
    fetchRoster();
  }, [emp_id]);
  //console.log(profiledata.department_name);

  useEffect(() => {
    const fetchWfhData = async () => {
      try {
        const endpoint = `work-from-home-attendance/${emp_id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.status === 200) {
          getWfh(response.data.wfh_details);
        } else {
          console.error("Error fetching work from home data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching work from home data:", error);
      }
    };
    fetchWfhData();
  }, [emp_id]);

  useEffect(() => {
    const fetchLeave = async () => {
      try {
        const endpoint = `leave-show-in-attendance/${branch}`;
        const response = await dashboardApi.get(endpoint);
        if (response.data.status === 200) {
          getLeave(response.data.data);
        } else {
          console.error('Error fetching leave data:', response.data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching leave data:', error);
        setLoading(false);
      }
    };
    fetchLeave();
  }, [branch]);

  useEffect(() => {
    const fetchWorkingDay = async () => {
      try {
        const response = await dashboardApi.get(`show-working-days/${emp_id}`);
        if (response.data.status === 200) {
          setWorkingDay(response.data.data);
        } else {
          console.error('Error fetching working day data:', response.data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching working day data:', error);
        setLoading(false);
      }
    };
    fetchWorkingDay();
  }, [emp_id]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await dashboardApi.get(`emp-profile/${emp_id}`);
        if (response.data.status === 200) {
          getprofiledata(response.data.data[0]);
        } else {
          console.error('Error fetching employee profile:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching employee profile:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchProfileData();
  }, [emp_id]);
  //console.log(selectedYear);

  useEffect(() => {
    const selectedDate = (selectedYear && selectedmonth) ? new Date(selectedYear,selectedmonth) : new Date();
    const currentDate = selectedDate;
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    //alert(firstDayOfMonth);
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    const days = [];
    for (let day = 1; day <= lastDayOfMonth; day++) {
      
      const date = new Date(year, month, day);
      const formattedDate = date.toLocaleDateString('en-CA'); 
      const weekday = date.toLocaleString('en-US', { weekday: 'long' });

      const apiDataForDate = attandence.find((entry) => entry.date === formattedDate) || {};
      const isLeaveDay = leave.find((entry) => entry.date === formattedDate);
      const isWorkDay = workingday.find((entry) => entry.date === formattedDate);
      const isLeaveTakingDay = leaveTakingData.find((entry) => entry.date === formattedDate);
      const isWfh = wfh.find((entry) => entry.date === formattedDate);
      const isRoster = roster.find((entry) => entry.week_off === formattedDate);
      days.push({
        day: formattedDate,
        weekday,
        apiData: apiDataForDate,
        leavedata: isLeaveDay,
        workdata: isWorkDay,
        leavetaken: isLeaveTakingDay,
        wfhdata: isWfh,
        rosterdata: isRoster
      });
    }
    setDaysInMonth(days);
  }, [attandence, leave, searchdata, workingday, leaveTakingData, wfh, roster,selectedYear,selectedmonth]);

  const handleDateChange = (e) => {
    setSearchData(e.target.value);
  };

  const handleMouseEnter = (rowData) => {
    setHoveredRowData(rowData);
    
  };

  const handleMouseLeave = () => {
    setHoveredRowData(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox  mb={3}>
          <div className="container">
             <div className="d-flex flex-wrap justify-content-around">
             <div className="col-sm-4 col-12 w-100">
                  <select
                    value={selectedmonth}
                    onChange={(e) => setselectedmonth(e.target.value)}
                    className="form-control"
                  >
                    <option value=""> Select Month </option>
                    <option  value="0">January</option>
                    <option  value="1">February</option>
                    <option  value="2">March</option>
                    <option  value="3">April</option>
                    <option  value="4">May</option>
                    <option  value="5">June</option>
                    <option  value="6">July</option>
                    <option  value="7">August</option>
                    <option  value="8">September</option>
                    <option  value="9">October</option>
                    <option  value="10">November</option>
                    <option  value="11">December</option>
                  </select>
              </div>
              <div className="col-sm-4 col-12">
                  <select
                    value={selectedYear}
                    onChange={handleYearChange}
                    className="form-control"
                  >
                    <option value="">
                     Select Year
                    </option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>

              </div>

             </div>
          </div>
          <br />
          <div className="container">
            <div className="row">
              <div className="col-md-12"style={{overflowY:'hidden'}} >
                <table className="table table-bordered table-hovered">
                  <tbody>
                    <tr>
                      <td>Date</td>
                      <td>Day</td>
                      <td>Sign In Time</td>
                      <td>Sign Out Time</td>
                      <td>Working Hours</td>
                      <td>Status</td>
                      <td>Idle Time</td>
                      <td>Break Time</td>

                    </tr>
                    {daysInMonth.map(({ day, weekday, apiData, leavedata, workdata, leavetaken, wfhdata, rosterdata }) => (
      
                      <tr style={{color:'black', fontSize:'20px',fontWeight:'bold'}} key={day}   onMouseEnter={() => handleMouseEnter({ day, weekday, apiData, leavedata, workdata, leavetaken, wfhdata, rosterdata })}
                          onMouseLeave={handleMouseLeave} className={`
                          ${((weekday === 'Sunday' || weekday === 'Saturday') && (department_name !== 'Customer Support' && profiledata.department_name!=='Customer Support')) ? 'weekend-row ' : ''}
                          ${apiData.status === 'Absent' ? 'absent-row ' : ''}
                          ${apiData.status === 'Present' ? 'present-row ' : ''}
                          ${apiData.status === 'Half Day' ? 'half-row ' : ''}
                          ${leavedata ? 'leave-row ' : ''}
                          ${workdata ? 'work-row ' : ''}
                          ${leavetaken ? 'leave-taking-row ' : ''}
                          ${wfhdata ? 'wfh-taking-row ' : ''}
                          ${rosterdata ? 'roster-taking-row ' : ''}
                          `.trim()}>

                        <td>{day}</td>
                        <td>{weekday}</td>
                        {(weekday === 'Sunday' || weekday === 'Saturday') && !workdata && (department_name !== 'Customer Support' && profiledata.department_name!=='Customer Support') || rosterdata ? (
                          <>
                            <td>OFF</td>
                            <td>OFF</td>
                            <td>OFF</td>
                            <td>OFF </td>
                            <td>OFF</td>
                            <td>OFF </td>
                            
                            
                          </>
                        ) : leavedata ? (
                          <>
                            <td>{leavedata.leave_name}</td>
                            <td>{leavedata.leave_name}</td>
                            <td>{leavedata.leave_name}</td>
                            <td>{leavedata.leave_name}</td>
                            <td>{leavedata.leave_name}</td>
                            <td>{leavedata.leave_name}</td>
                          </>
                        ) : leavetaken ? (
                          <>
                            <td>{leavetaken.name}</td>
                            <td>{leavetaken.name}</td>
                            <td>{leavetaken.name}</td>
                            <td>{leavetaken.status}</td>
                            <td>{leavetaken.name}</td>
                            <td>{leavetaken.name}</td>
                          </>
                        ) : (
                          <>
                            <td>{apiData.login_time}</td>
                            <td>{apiData.logout_time}</td>
                            <td>{apiData.working_hours}</td>
                            <td>{apiData.status}</td>
                            <td>{apiData.idle_time}</td>
                            <td>{apiData.break_time}</td>
                          </>
                        )}
                         {hoveredRowData && day === hoveredRowData.day && (
                          <Popup data={hoveredRowData} />
                          )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;