import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import './filter.css'

function TargetOverview() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
 // alert(dept_id);
  const token = secureLocalStorage.getItem('token');
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [targetList, settargetlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(5);
  const [loading,setLoading] = useState(false);
  const [grouplist,setgrouplist] = useState([]);
  const [categorylist,setcategorylist] = useState([]);
  const [category,setcategory] = useState('');
  const [openModal,setopenModal] = useState(false);
  const [openeditmodel,setopeneditmodel] = useState(false);
  const [employee,setemployee] = useState([]);
  const [selectedemployee,setselectedemployee] = useState('');
  const [remark,setremark] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [value,setvalue] = useState('');
  const [assigngroup,setassigngroup] = useState('');
  const [assigncategory,setassigncategory] = useState('');
  const [selectedmonth,setmonth] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [financial_year,set_financialyear] = useState('');
  const [groupid,setgroupid] = useState('');
  const [categoryid,setcategoryid] = useState('')
  const [lead ,setlead] = useState('');
  const [selectedgroup,setselectedgroup] = useState('');
  const [remaining,getremaining] = useState('');
  const [assignmonth,setassignmonth] = useState('');
  const [assignyear,setassignyear] = useState('');
  const [source,setsource] = useState('');
  const [productlist,setproductlist] = useState([]);
  const [product,setproduct] = useState('');
  const [servicelist, setservicelist] = useState([]);
  const [service, setservice] = useState('');
  const [service_data,setservice_data] = useState([]);
 
  const [searchText, setSearchText] = useState('');
  const [selectedchild_attribute,setselectedchildattribute] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const[employeedetails,setemployeedetails] = useState([]);
  const[amount_target,fetchamount_target] = useState([]);

   const [inputData, setInputData] = useState({});
  const filteredGroups = searchText
    ? employee.filter(row => row.emp_fname.toLowerCase().includes(searchText.toLowerCase()))
    : employee;

  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var startYear, endYear;
if (currentDate.getMonth() < 3) {
    startYear = currentYear - 1;
    endYear = currentYear;
} else {
    startYear = currentYear;
    endYear = currentYear + 1;
}
const curr_fyear =  startYear + " - " + endYear;
const month = currentDate.toLocaleString('default', { month: 'long' });
//alert(month);

   const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  //console.log(remaining);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-product-list';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setproductlist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  
  //const [attribute,setattribute]= useState('');

  //alert(dept_id);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchStock();
    //fetchtarget();
  },[])

  useEffect(() => {
        fetchTarget(currentPage);
    }, [category, selectedgroup, selectedmonth, financial_year,  currentPage,product]);

    useEffect(()=>{
      fetch_amount_target(currentPage);

      } ,[category, selectedgroup, selectedmonth, financial_year,  currentPage,product])

      useEffect(()=>{
        service_wise_target_details();
  
        } ,[financial_year,product])

      


  useEffect(() => {
    const getCurrentFinancialYear = () => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
      const financialYearEnd = financialYearStart + 1;

      return `${financialYearStart}-${financialYearEnd}`;
    };

    console.log(service_data);


    const generateFinancialYears = () => {
      const currentFinancialYear = getCurrentFinancialYear();
      const nextFinancialYears = [];

      for (let i = 1; i <= 5; i++) {
        const startYear = parseInt(currentFinancialYear.split('-')[0]) + i;
        const endYear = startYear + 1;
        nextFinancialYears.push(`${startYear}-${endYear}`);
      }
      const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
      setFinancialYears(allFinancialYears);
    };

    generateFinancialYears();
  }, []);
 const fetchTarget = async (page = 0) => {
        try {
            setLoading(true);
            const response = await dashboardApi.get('get-sales-lead', {
                params: {
                    per_page: postPerPage,
                    page: page + 1,
                    category: category,
                    group: selectedgroup,
                    month: selectedmonth,
                    financial_year: financial_year,
                    //additional:additional,
                    id:empId,
                    product:product,
                }
            });
            if (response.data.status === 200) {
                const responseData = response.data.data;
                const stockdata = responseData.data || responseData;
                settargetlist(stockdata);
                setNumber(response.data.last_page);
                setLoading(false);

            } else {
                console.error('Error fetching data:', response.data.error);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };



    const fetch_amount_target = async (page = 0) => {
      try {
          setLoading(true);
          const response = await dashboardApi.get('lead-amount-details', {
              params: {
                  per_page: postPerPage,
                  page: page + 1,
                  category: category,
                  group: selectedgroup,
                  month: selectedmonth,
                  financial_year: financial_year,
                  //additional:additional,
                  id:empId,
                  product:product,
              }
          });
          if (response.data.status === 200) {
              const responseData = response.data.data;
              const amount_target = responseData.data || responseData;
              fetchamount_target(amount_target);
              setNumber(response.data.last_page);
              setLoading(false);

          } else {
              console.error('Error fetching data:', response.data.error);
              setLoading(false);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
      }
  };

  const service_wise_target_details = async () => {
    try {
        const response = await dashboardApi.get('service-wise-target-details', {
            params: {
                emp_id:empId,
                product:product,
                financial_year:financial_year,
            }
        });
        if (response.data.status === 200) {
            setservice_data(response.data.data);
            

        } else {
            console.error('Error fetching data:', response.data.error);
            setLoading(false);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
    }
};

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);




  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchTarget(selected.selected); 
};

  const handletargetamount = (selected)=>{
    setCurrentPage(selected.selected);
    fetch_amount_target(selected.selected);


  }



  const fetchStock = async (page = 0) => {
      try {
        const response = await dashboardApi.get('get-sales-lead', {
            params: {
              per_page: postPerPage,
              page: page + 1,
              id:empId,
              //emp_id:empId,
            }
          });
        if (response.data.status === 200) {
             const responseData = response.data.data;
             const stockdata = responseData.data || responseData;
             settargetlist(stockdata);
             setNumber(response.data.last_page);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'group-details';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
            setgrouplist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  //alert(department);

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setcategorylist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const endpoint = `sales-dashboard-show-manager-team/${empId}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
             setemployee(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
  }, []);


  // useEffect(() => {
  //       const fetchtarget = async () => {
  //           try {
  //               const response = await dashboardApi.get('get-group-employee', {
  //                   params: {
  //                       assigngroup:assigngroup,
  //                   }
  //               });
  //               if (response.data.status === 200) {
  //                 setemployee(response.data.data);
  //               } else {
  //                   console.error('Error fetching data:', response.data.error);
  //               }
  //               setLoading(false);
  //           } catch (error) {
  //               console.error('Error fetching data:', error);
  //               setLoading(false);
  //           }
  //       };
  //       fetchtarget();
  // }, [assigngroup]);

  useEffect(()=>{
    if(selectedchild_attribute.length>0){
       const fetchData = async () => {
       try {
          const response = await dashboardApi.get('emp-data', {
              params: {
               emp_ids:selectedchild_attribute
                //emp_id:empId,
              }
            });
          if (response.data.status === 200) {
           setemployeedetails(response.data.data)
               
          } else {
            console.error('Error fetching data:', response.data.error);
          }
          //setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
         // setLoading(false);
        }
  
    }
      fetchData();
  
  }
  
  },[selectedchild_attribute])
  

  const assign_to_team = async(group,category,month,financial_year)=>{
   // alert(category);
    try {
      const endpoint = `count-sales-lead-data/${group}/${category}/${month}/${financial_year}`;
      const response = await dashboardApi.get(endpoint);
      //console.log(response);

      if (response.data.status === 200) {

        getremaining(response.data.sales_data);
      } else {
        console.error('Error fetching:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setassigngroup(group);
    setassigncategory(category);
     setassignmonth(month);
    setassignyear(financial_year);
    setsource(source);
    setopenModal(true);
    }
 const handleclose = ()=>{
    setopenModal(false);
    setassigngroup('');
    setassigncategory('');
    setValidationErrors('');
    setselectedemployee('');
    setremark('');
    setvalue('');
    setassignmonth('');
    setassignyear('');
    setsource('');
    setselectedchildattribute([]);
    setemployeedetails([]);
    setAssignedTasks([]);

 }
 //alert(year);
 const editrow   = async (group,category,id)=>{
    setopeneditmodel(true);
    setgroupid(group);
    setcategoryid(category);
    setid(id);


    try {
      const endpoint = `lead-edit/${id}/${group}/${category}`;
      const response = await dashboardApi.get(endpoint);
      console.log(response);

      if (response.data.status === 200) {
        setlead(response.data.data);
      } else {
        console.error('Error fetching:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching', error);
    }

}
const updatedata = async(e)=>{
  e.preventDefault();
  //alert();
  const formData = new FormData()
  formData.append('lead', lead)
  try {
    const response = await dashboardApi.post(`save-edit-lead/${id}/${groupid}/${categoryid}`, formData);
    //console.log(response);

    if (response.data.status === 200) {
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      fetchStock();
      //setopeneditmodel(false);
      handleeditclose();
    } else if (response.data.status === 400) {
      // Display validation errors below the input fields
      setValidationErrors(response.data.messages);
    } else {
      Swal.fire({
        icon: 'error',
        text: response.data.message || 'An error occurred',
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'An error occurred',
    });
  }



}
//alert(assignyear);
 const handlesave = async (e) => {
  e.preventDefault();
  const errors = {};
  setValidationErrors(errors);
 if (Object.keys(errors).length === 0) {
  const formData = new FormData()
  formData.append('emp_id',empId);
  formData.append('department',dept_id);
  formData.append('group',assigngroup);
  formData.append('category',assigncategory);
  formData.append('employee',selectedemployee);
  formData.append('remark',remark);
  formData.append('value',value);
  formData.append('year',assignyear);
  formData.append('month',assignmonth);
  formData.append('source',source)
  formData.append('emp_type',1);
  formData.append('emp_ids',JSON.stringify(assignedTasks));
  try {
    const response = await dashboardApi.post('save-assign-target', formData);

    if (response.data.status === 200) {
      //console.log('iiii');
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      fetchStock();
      handleclose();

    } else if (response.data.status === 400) {
      // Display validation errors below the input fields
      setValidationErrors(response.data.messages);
    }
    else if (response.data.status === 201) {
      Swal.fire({
        icon: 'error',
        text: response.data.message,
      });
    }
     else {
      Swal.fire({
        icon: 'error',
        text: response.data.message || 'An error occurred',
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'An error occurred while creating the stock',
    });
  }
};
}
const handleeditclose = ()=>{
  setlead('');
  setValidationErrors('');
  setopeneditmodel(false);
}
const assigntask = (e,id)=>{
  
  const existingIndex = assignedTasks.findIndex(task => task.emp_id === id);

   // If the object exists, update its value; otherwise, create a new object
   if (existingIndex !== -1) {
     const updatedTasks = [...assignedTasks];
     updatedTasks[existingIndex] = { emp_id:id, value:e };
     setAssignedTasks(updatedTasks);
   } else {
     setAssignedTasks([...assignedTasks, { emp_id:id, value:e }]);
   }

}
//console.log(selectedattribute);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
                <center style={{color:'red'}}><h6 >Target Overview</h6>
                 Total Target : 50000, Acheived Target : 3000 , Remaining Target :40000,

                 </center>

                 <div className='container'>
                    <div className='row'>
                    <div className='col-md-3'>
                        <h5>Choose Product</h5>
                        <select
                          className="form-control form-select"
                          value={product}
                          onChange={(e) => setproduct(e.target.value)}
                          aria-label="Default select example"
                          style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                        >
                         <option value="">Select Product</option>
                         {productlist.map((row) => (
                            <option key={row.id} value={row.id}>
                            {row.product_name}
                            </option>
                            ))}
                         </select>
                         </div>

                    <div className="col-md-3">
                    <h5>Select Financial year</h5>
                      <select
                         className="form-control form-select"
                         value={financial_year}
                         onChange={(e) => set_financialyear(e.target.value)}
                         style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                        >
                        <option value ="">Select year</option>
                        {financialYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                     ))}
                  </select>
                  </div>

                    </div>
                     </div>
                     <center>Total Target Details Service Based </center>
                     <div className="table-responsive">
                     <table className="table table-hovered table-striped">
                   <thead>
                 <tr>
      {servicelist.map((ser) => (
        <td key={ser.id}>{ser.service_name}</td>
      ))}
      <td>Total</td>
    </tr>
  </thead>
  <tbody>
    <tr>
      {servicelist.map((ser) => {
        const matchedService = service_data.find(
          (item) => item.service_id === ser.id
        );
        return (
          <td key={ser.id}>
            {matchedService ? matchedService.total_target_amount : "0"}
          </td>
        );
      })}
      <td>
        {
          service_data.reduce(
            (sum, item) => sum + parseFloat(item.total_target_amount || 0),
            0
          )
        }
      </td>
    </tr>
  </tbody>
</table>


                    </div>


            </Card>

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TargetOverview;