import React, { useState, useEffect } from 'react';
import { Grid, Card, Box, Typography, Button, TextField } from '@mui/material';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import dashboardApi from 'layouts/common_components/apibase_url';
import  secureLocalStorage  from  "react-secure-storage";
import Swal from 'sweetalert2';
import { Link,useNavigate} from 'react-router-dom';

function CompanyTargetList() {
  const [selectedDepartment, setSelectedDepartment] = useState('');
 // const [selectedTargetType, setSelectedTarget] = useState('');
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();
  //const [categoryList, setCategoryList] = useState([]);
  //const [vendorList, setVendorList] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [inputcategory,setinputcategory] = useState({});
  const [attributelist,setattributelist] = useState([]);
  const [selectedattribute,setselectedattribute] = useState([]);
  const [type,settype] = useState('');
  const [grouplist,setgrouplist] = useState([]);
  const [group,setgroup] = useState('');
  const [categorylist,setcategorylist] = useState([]);
  const [category,setcategory] = useState([]);
  const [file, setFile] = useState(null);
  const date = new Date();
  let year = date.getFullYear();
 const emp_id = secureLocalStorage.getItem('emp_id');
 const [financialYears, setFinancialYears] = useState([]);
 const [financial_year,set_financialyear] = useState('');
 const [validationErrors, setValidationErrors] = useState({});
 const [total,settotal] = useState('');
 const [inputgroups,setinputgroups] = useState({});
 const [subattribute,setsubattribute] = useState([]);
   const [showAssignToGroups, setShowAssignToGroups] = useState(false);
   const [valuetype, setvaluetype] = useState('');
   const [product,setproduct] = useState('');
   const [attribute,setattribute] = useState([]);
   const [selectedgoal,setselectedgoal] = useState('');
   const [goalattribute,setgoalattribute] = useState([]);
   const [selectedgoalattribute,setselectedgoalattribute] = useState('');
   const [goalsubattribute,setgoalsubattribute] = useState([]);
   const [selectedgoalsubattribute,setselectedgoalsubattribute] = useState([]);
   const [goalsub_subattribute,setgoalsub_subattribute] = useState([]);
   const [selectedgoalsubsubattribute, setselectedgoalsubsubattribute] = useState('');
   const [categoryview,setcategoryview] = useState(false);
   const [inputvalues, setinputvalues] = useState('');
   const [sourcecategory,setsourcecategory] = useState([]);
   const [source,setsource] = useState([]);
   const [organic,setorganic] = useState(0);
   const [apiData, setApiData] = useState([]);
   const [inputData, setInputData] = useState({});
   const [adword,setadword]  =  useState(0);
   const [finaldata,setfinal_data] = useState('');
   const [runningOrgData, setRunningOrgData] = useState(0);
  const [runningAdwData, setRunningAdwData] = useState(0);
  const [ischeckcategory,setischeck_category] = useState(0);
  const [showtotal,setshowtotal] = useState(false);
  const [productlist,getproductlist] = useState([]);
  const [sourcelist,setsourcelist] = useState([]);
  const [calc_data,setcalc_data] = useState({});
  const categoryTotals = {};
  const [categoryValues, setCategoryValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [group_list, set_group_list] = useState([]);
  const [selectedgroup, setselectedgroup] = useState('');
  const[target_type,set_target_type] = useState('');
  const [value_type,set_value_type] = useState('');
  const [selectedvaluetype,setselectedvaluetype] = useState('');
  const [servicelist, setServicelist] = useState([]);
  const [service, setservice] = useState('');
   let type_value = '';
   let  monthly_assign  = '';


  // Event handler for when a radio button is selected
  const handleGenderChange = (event) => {
    setselectedvaluetype(event.target.value);
  };
  //let categoryValues = {};
  // let inputtype = '';
  const inputtype = total ? 'number' : 'text';

  if(selectedvaluetype=='numbers' || selectedvaluetype=='percent' ){
     type_value ='number'

  }
  else{
     type_value ='text'

  }
  //console.log(category);


  const handleChange = (e) => {
    setvaluetype(e.target.value);
  };
  useEffect(()=>{
    fetchattributelist();

  },[])
  const handlevaluetypechange = (event) => {
    setselectedvaluetype(event.target.value);
  };

const calculate_category_data = (e, cat_id, id) => {
    let calculate_value = 0;
    let e_id = id === null ? 0 : id; 
    let a = categoryValues[cat_id] || 0;
    let e_data = 0;

    if (id === null) {
       
        let calculatedValue = parseInt(e);
        let calc = (total * calculatedValue) / 100;
        setCategoryValues(prevState => ({
            ...prevState,
            [cat_id]: calc
        }));

        a = calc; 
    } else {
        
        if (categoryValues.hasOwnProperty(cat_id)) {
             e_data = parseInt(e);
            let catValue = categoryValues[cat_id];
            calculate_value = (catValue * e_data) / 100;

            a = catValue; 
        }
    }
  
    setcalc_data(prevInputData => ({
        ...prevInputData,
        [`${cat_id}_${e_id}`]: { value: a, catId: cat_id, finalvalue: id, e_value: e_data }
    }));
};
  console.log(calc_data);


   const calculated_data = Object.entries(calc_data).map(([key, { value, catId,finalvalue,e_value }]) => ({
   id: parseInt(key),
   value: value,
   data: catId,
   finalvalue:finalvalue,
   e_data:e_value,
   }));

const category_object = JSON.stringify(calculated_data);
console.log(category_object);


const handleInputselectedcategory = (itemId, event, catId) => {
    let { value } = event.target;
    value = value.replace(/[^0-9.]/g, '');
    if (!value || isNaN(parseFloat(value))) {
        value = '0';
    }
    let orgdata = 0;
    let adwdata = 0;

    if (itemId === 1) {
       if(inputvalues=="Percent"){
         orgdata = (organic * parseFloat(value)) / 100;

       }
       else{
         orgdata = parseFloat(value);


       }
        
        setRunningOrgData(orgdata);
    } else if (itemId === 2) {
       if(inputvalues=="Percent"){
         adwdata = (adword * parseFloat(value)) / 100;

       }
       else{
         adwdata = parseFloat(value);


       }
        setRunningAdwData(adwdata);
    }
    const finalValue = orgdata + adwdata;
    setInputData(prevInputData => ({
        ...prevInputData,
        [`${itemId}_${catId}`]: { value: value, catId: catId, finalValue: finalValue }
    }));
};
 useEffect(() => {
    const validOrgData = isNaN(runningOrgData) ? 0 : runningOrgData;
    const validAdwData = isNaN(runningAdwData) ? 0 : runningAdwData;
    const finalvalues = validOrgData + validAdwData;
    setfinal_data(finalvalues);
}, [runningOrgData, runningAdwData]);

   const categoryattribute = Object.entries(inputData).map(([key, { value, catId,finalValue }]) => ({
   id: parseInt(key),
   value: value,
   data: catId,
   finalvalue:finalValue,
   }));

const categorydatas = JSON.stringify(categoryattribute);

const calculateTotalForCategory = (categoryItems) => {
  // Initialize total
  let total = 0;
  categoryItems.forEach((item) => {
    total += parseFloat(inputData[`${item.id}_${item.cat_id}`]?.value) || 0;
  });
  //console.log(total);

  // Return the total as a string
  return total.toString();
};


 //alert(financial_year);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-active-department';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setDepartments(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setServicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);



   useEffect(() => {
    if(service){
    const fetchData = async () => {
      try {
        const endpoint = `product-categoey-list/${service}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setsourcelist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }
  }, [service]);
  const calculate_data = (e,category_id,id)=>{

   // alert(id);

  }
  // const calculate_category_wise_data = (total,cat_id)=>{
  //   alert(cat_id);
  // }





  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-product-list';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          getproductlist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

    useEffect(()=>{
      if (sourcecategory && sourcecategory.length > 0) {
      const fetchData = async () => {
        try {
          const endpoint = `source-category`;
          const response = await dashboardApi.get(endpoint, {
            params: {
              sourcecategory: sourcecategory.join(',')
            }
          });
    
          if (response.data.status === 200) {
            setsource(response.data.data);

          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }

    },[sourcecategory])
   // console.log(source);
   const fetchattributelist = async () => {
       try {
        const endpoint = 'active-attribute';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
     }
  //alert(selectedTargetType);
  //useEffect(()=>{
    const calculatePercentage = (goalValue, totalValue, id) => {
      let calculatedValue = 0;
    if (totalValue === 0) {
      return 0;
    }

   if(inputvalues === 'Percent'){
      calculatedValue = (goalValue * totalValue) / 100;
   }
   else{
    calculatedValue = (goalValue * 100) /totalValue +" " +'%';

     

   }
    return calculatedValue;
  };
 

  //console.log(organic);
  //console.log(adword);
  //calculatePercentage(inputValues[goalsub_subattribute[index + i].id], parseFloat(total), goalsub_subattribute[index + i].id, setorganic, setadword);
  const calculatecategory = (goalValue, totalValue) => {
    if (totalValue === 0) {
      return 0; // Return 0 if totalValue is 0 to avoid division by zero
    }
    if(inputvalues=='Percent'){
      return ( totalValue*goalValue)/ 100;

    }
    // if(inputvalues=='Amount'){
    //   return ( totalValue*goalValue)/ 100;

    // }
  };
  let remaining_percent = 100;
  let orgdata = organic;
  let adwdata = adword;
  let adw_percent = 100;
  let total_orginputs = 0;
  let total_adwinputs = 0;
  let sum_of_data = 0;
  const calculatecategorydata = (id, e,cat_id) => {
   //console.log('jj',organic);
    if(id === 1){
     // let remaining_percent = 0;
      if(inputvalues == 'Percent'){
        remaining_percent -= e;
        total_orginputs  += (organic * e) / 100;
        //console.log(total_orginputs);
        if(remaining_percent<0){
          alert('Already 100 percent filled');
          //confirm(message);  

        }
       // return (organic*e)/100  +" " + 'remaining percent'+' '+ remaining_percent;
        //return `${(organic * e) / 100}\nremaining percent ${remaining_percent}`;
         return (
          <div>
          {`${(organic * e) / 100}`} <br />
           {'remaining percent ' + remaining_percent}
         </div>
         );


      }
      else{
       total_orginputs += parseFloat(e);
        //alert(e);
         orgdata -= e;
         if(orgdata<0){
          alert(' No Of Leads Not Avaliable');

         }

       // return (e*100)/organic +" "+"%";
        //return `${(e*100) / organic}\nremaining Number ${orgdata}`;
         return (
          <div>
          {`${(e*100) /organic}`} % <br />
           {'remaining data ' + orgdata}
         </div>
         );
      }


    }
    if(id=== 2){
      if(inputvalues =='Percent'){
         adw_percent -= e;
         total_adwinputs += (adword * e)/100;
        if(adw_percent<0){
          alert('Already 100 percent filled');
          //confirm(message);  
         }
         sum_of_data = total_adwinputs+total_orginputs;

          return (
          <div>
          {`${(adword * e) / 100}`} <br />
           {'remaining percent ' + adw_percent}
         </div>
         );




      }
      else{
        adwdata -= e;
        total_adwinputs += parseFloat(e);
        //total_adwinputs += e;
        if(adwdata<0){
         alert(' No Of Leads Not Avaliable');

        }
        sum_of_data = total_adwinputs+total_orginputs;
       // return (e*100)/adword  +" "+"%";
       // return `${(e*100) / adword}\nremaining Number ${adwdata}`;
         return (
          <div>
          {`${(e*100) /adword}`} % <br />
           {'remaining data ' + adwdata}
         </div>
         );
      }

    }
    
    // if(inputvalues=='Amount'){
    //   return ( totalValue*goalValue)/ 100;

    // }
  };
  const calculate_percentage = (val,total)=>{
    //alert(val);
    return val;

  }


   useEffect(() => {
if (selectedgoalattribute && selectedDepartment){
   //setInputValues('');
    const fetchData = async () => {
      try {
        const endpoint = `goal-based-subattribute/${selectedgoalattribute}/${selectedDepartment}`;
        //const endpoint = `count-stock/${stock}`;
        const response = await dashboardApi.get(endpoint);
        //console.log(response);

        if (response.data.status === 200) {
          setgoalsubattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
}
  }, [selectedgoalattribute,selectedDepartment]);


 useEffect(() => {
if (selectedgoalsubattribute && selectedDepartment){
  //alert();
   //setInputValues('');
    const fetchData = async () => {
      try {
        const endpoint = `goal-based-sub-subattribute/${selectedgoalsubattribute}/${selectedDepartment}`;
        //const endpoint = `count-stock/${stock}`;
        const response = await dashboardApi.get(endpoint);
        //console.log(response);

        if (response.data.status === 200) {
          setgoalsub_subattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
}
  }, [selectedgoalsubattribute,selectedDepartment]);


    useEffect(() => {
if (selectedgoal && selectedDepartment){
   //setInputValues('');
    const fetchData = async () => {
      try {
        const endpoint = `goal-based-attribute/${selectedgoal}/${selectedDepartment}`;
        //const endpoint = `count-stock/${stock}`;
        const response = await dashboardApi.get(endpoint);
       // console.log(response);

        if (response.data.status === 200) {
          setgoalattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
}
  }, [selectedgoal,selectedDepartment]);
   //console.log(selectedDepartment);
  useEffect(() => {
  setInputValues({});
}, [selectedattribute]);

/* useEffect(() => {
if(selectedattribute){
    const fetchData = async () => {
      try {
        const endpoint = `get-subattribute/${selectedattribute}`;
        //const endpoint = `count-stock/${stock}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setsubattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
}
  }, [selectedattribute]);*/
  //console.log(subattribute);

  useEffect(() => {
    const getCurrentFinancialYear = () => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
      const financialYearEnd = financialYearStart + 1;

      return `${financialYearStart}-${financialYearEnd}`;
    };

    const generateFinancialYears = () => {
      const currentFinancialYear = getCurrentFinancialYear();
      const nextFinancialYears = [];

      for (let i = 1; i <= 5; i++) {
        const startYear = parseInt(currentFinancialYear.split('-')[0]) + i;
        const endYear = startYear + 1;
        nextFinancialYears.push(`${startYear}-${endYear}`);
      }
      const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
      setFinancialYears(allFinancialYears);
    };

    generateFinancialYears();
  }, []);

  useEffect(() => {
        const fetchData = async () => {
          try {
            const endpoint = 'group-details';
            //const endpoint = `count-stock/${stock}`;
            const response = await dashboardApi.get(endpoint);
    
            if (response.data.status === 200) {
                set_group_list(response.data.data);
            } else {
              console.error('Error fetching data:', response.data.message);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []);
      //alert(product);

      useEffect(() => {
         if(product){
            const fetchData = async () => {
              try {
                const endpoint = `category-details/${product}`;
                //const endpoint = `count-stock/${stock}`;
                const response = await dashboardApi.get(endpoint);
               // console.log(response);
        
                if (response.data.status === 200) {
                    setcategorylist(response.data.data);
                } else {
                  console.error('Error fetching data:', response.data.message);
                }
              } catch (error) {
                console.error('Error fetching data:', error);
              }
            };
            fetchData();
         }

        
          }, [product]);
         // console.log(categorylist);
    const blank_attribute =()=>{
      setInputValues('');
      setinputvalues('');
     // setgroup('');
      setcategory('');
      setSelectedDepartment('');
      setinputgroups('');
      //set_financialyear
      //setSelectedTargetType('');
      set_financialyear('');
      settotal('');
      setselectedattribute('');
      setShowAssignToGroups(false);
      setcategoryview('');
      setproduct('');
      setselectedgoal('');
      setselectedgoalattribute('');
      setselectedgoalsubattribute([]);
      settotal('');
      setsourcecategory([]);
      setInputData([]);
      setsourcecategory([]);
       setsource([]);
       setcalc_data('')






    }
    const handleFileChange = (e) => {
      e.preventDefault();
      setFile(e.target.files[0]);
    };
    const upload_excel = async () => {
      if (!file) {
        alert('Please select a file');
        return;
      }
    
      const formData = new FormData();
      formData.append('file', file);
    
      try {
        const response = await dashboardApi.post('import-target-excel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
    
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          setFile(null);
          const inputFile = document.getElementById('file-upload');
          inputFile.value = null;
    
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: error.message,
        });
      }
    };




  const handleDepartmentChange = e => {
    setSelectedDepartment(e.target.value);
    //setSelectedTargetType('');
  };
  const toggleShowdata = (isChecked)=>{
    setshowtotal(!showtotal);
    if(!isChecked){
      settotal('');
      setinputvalues('');

    }
  }


  const handleInputChange = (e, id) => {
    const { value } = e.target;
    let totaldata = ''
    if(id==1){
      if(inputvalues == "Percent"){
         totaldata = (value*total)/100;

      }
      else{
        totaldata = value;

      }

      setorganic(totaldata);
    }
    if(id==2){
      if(inputvalues == "Percent"){
        totaldata = (value*total)/100;

      }
      else{
         totaldata = value;


      }
       setadword(totaldata);
    }
    setInputValues(prevState => ({
      ...prevState,
      [id]: value,
    }));
   
  };
  // const handlegroupchange = (e, id) => {
  //   const { value } = e.target;
  //   setinputgroups(prevState => ({
  //     ...prevState,
  //     [id]: value,
  //   }));
   
  // };

  // const handlecategorychange = (e, id) => {
  //   const { value } = e.target;
  //   const org = organic;
  //   setinputcategory(prevState => ({
  //     ...prevState,
  //     [id]: value,
  //   }));
   
  // };
  //console.log(inputcategory);
  const saveData = async () => {
    //console.log(inputValues);
    const datainput = Object.entries(inputValues).map(([key, value]) => ({
        id: parseInt(key),
        value: value
      }));
      const jsonData = JSON.stringify(datainput);

      const grouparray = Object.entries(inputgroups).map(([key, value]) => ({
        id: parseInt(key),
        value: value
      }));
      const groupdata  = JSON.stringify(grouparray);

      const categoryarray = Object.entries(inputcategory).map(([key, value]) => ({
        id: parseInt(key),
        value: value
      }));
      const categorydata  = JSON.stringify(categoryarray);
      //console.log(jsonData);
     //const inputDataArray = Object.values(inputData); // Get an array of values from the object

// Map over the array of objects
/*const calculated_data = inputDataArray.map(({ id, value, catId, finalValue }) => ({
    id: id,
    value: value,
    catId: catId,
    finalValue: finalValue,
}));

// Stringify the resulting array of objects
const calculatedvalue = JSON.stringify(calculated_data);*/

const groupDetails = async () => {
  try {
    const response = await dashboardApi.get('group-details');
    if (response.data.status === 200) {
      setGroupList(response.data.data);
    } else {
      console.error('Error fetching data:', response.data.error);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

if(!selectedDepartment){
  alert('Department is required');

}
if(!selectedgoal){
  alert('Goal is required');

}
if(!selectedgoalattribute){
  alert('Attribute is required');

}
if(!selectedgoalsubattribute){
  alert('SubAttribute is required');

}
if(showtotal==true){
  monthly_assign == 1;

}
else{
  monthly_assign == 0;

}


      
      //console.log(dataArray);
    const formData = new FormData();
    formData.append('department',selectedDepartment);
    //formData.append('targetType',selectedTargetType);
   formData.append('inputValues',jsonData);
    formData.append('category',category);
    formData.append('emp_id',emp_id);
    formData.append('financial_year',financial_year);
    formData.append('group_data',groupdata);
    formData.append('category_data',categorydata);
    formData.append('total',total);
    formData.append('attribute',selectedgoalattribute);
    formData.append('sub_attribute',selectedgoalsubattribute);

    //formData.append('subattribute',)
    formData.append('valuetype',selectedvaluetype);
    formData.append('product',product);
    formData.append('service',service);
    formData.append('goal',selectedgoal);
    formData.append('dataarray',category_object);
    formData.append('group_id',selectedgroup);
    formData.append('target_type',target_type);
    formData.append('is_monthly_assign',showtotal);



    //formData.append('')

    try {
        const response = await dashboardApi.post('save-target', formData);
        //console.log(response);
  
        if (response.data.status === 200) {
          //alert('kkk');
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
           navigate("/target-details");
          //handleCloseModal();
          blank_attribute();
  
        } else if (response.data.status === 400) {
          setValidationErrors(response.data.messages);
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: error.message || 'An error occurred while creating the enquiry',
        });
      } 

    
  };
  //alert(ischeckcategory);
  const toggleAssignToGroups = () => {
    setShowAssignToGroups(!showAssignToGroups);
  };
  const toggleAssignToCategory = (isChecked) => {
   setcategoryview(!categoryview);
    if (isChecked) {
      setischeck_category(1);
    } else {
       setischeck_category(0);
        setInputData([]);
        setsourcecategory([]);
        setsource([]);
    }
};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={9} md={10}>
            <Card variant="outlined">
              <Box p={4}>
              <div className="container">
                <div className="row">
                 <div className="col-md-2">
                    <Link className="btn btn-success btn-sm" to="/target-details">Back</Link>
                    
                  </div>
                  <div className="col-md-2">
                    <Link className="btn btn-info btn-sm" to="/company-goal-list">Goal</Link>

                  </div>
                  &nbsp;&nbsp;&nbsp;
                  
                   <div className="col-md-2">
                    <Link className="btn btn-info btn-sm" to="/subattribute-list">Attribute</Link>
                    
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  
                  <div className="col-md-2">
                    <Link className="btn btn-info btn-sm" to="/target-subattribute">Subattribute</Link>
                    
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  

                  <div className="col-md-3">
                    <Link className="btn btn-info btn-sm" to="/target-child-attribute">Child Attribute</Link>
                    
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  
                  {/* <div className="col-md-4">
                  <input type="file" onChange={handleFileChange} className='form-control' id="file-upload" />

                  </div>
                  <div className="col-md-1">
                    <button className='btn btn-info' onClick={upload_excel}>Insert</button>

                  </div> */}
                  <br/>
                  

                </div>
                
              </div>
                <Typography variant="h4" gutterBottom>
                  <center style={{color:'green'}}>Goal Setting Of RIMS For Year {year}</center><hr/>
                </Typography>
                 <div style={{ marginBottom: '16px' }}>
                  <label htmlFor="department" style={{fontSize:'15px'}}>Select Product:</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Product</option>
                    {productlist.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.product_name}
                      </option>
                    ))}
                   
                  </select>
                </div>
                <div style={{ marginBottom: '16px' }}>
                  <label htmlFor="department" style={{fontSize:'15px'}}>Select Department:</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Department</option>
                    {departments.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.department_name}
                      </option>
                    ))}
                  </select>
                  
                </div>
                 <div style={{ marginBottom: '16px' }}>
                  <label htmlFor="department" style={{fontSize:'15px'}}>Select Goal:</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedgoal}
                    onChange={(e) => setselectedgoal(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Goal</option>
                    {attribute.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.attribute_name}
                      </option>
                    ))}
                  </select>
                  
                </div>
                 <div style={{ marginBottom: '16px' }}>
                  <label htmlFor="department" style={{fontSize:'15px'}}>Select Attribute:</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedgoalattribute}
                    onChange={(e) => setselectedgoalattribute(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Attribute</option>
                    {goalattribute.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.attribute_name}
                      </option>
                    ))}
                  </select>
                </div>

                 <div style={{ marginBottom: '16px' }}>
                  <label htmlFor="department" style={{fontSize:'15px'}}>Select Sub Attribute:</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedgoalsubattribute}
                    onChange={(e) =>  setselectedgoalsubattribute(Array.from(e.target.selectedOptions, (option) => option.value))}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select subAttribute</option>
                    {goalsubattribute.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.subattribute_name}
                      </option>
                    ))}
                  </select>
                </div>
                
                  <div>
                  <div>

      {/* {showtotal &&
      <label>
        <input
          type="radio"
          value="Amount"
          checked={inputvalues === 'Amount'}
          onChange={handleGenderChange}
        />
        Number
      </label>
      }  
      &nbsp;&nbsp; */}
    </div>

      </div>


            <div style={{ marginBottom: '16px' }}>
                  <label htmlFor="department" style={{fontSize:'15px'}}>Select Target Type:</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={target_type}
                    onChange={(e) =>set_target_type(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value=''>Select Target Type</option>
                    <option value='lead'>Lead Target</option>
                    <option value ='department_based'>Department Based</option>
                  </select>
                </div>

                  {target_type ==='lead' &&
                  <div style={{ marginBottom: '16px' }}>
                    <label htmlFor="department" style={{fontSize:'15px'}}>Enter Total Number Of Lead:</label>
                     <input type = "number"
                      value={total}
                      onChange={(e) =>settotal(e.target.value)}
                      style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                      placeholder = "Enter Total Number"
                     />
                  </div>
                   }  
                  
{target_type ==='lead' &&
  <div>

   <div style={{ marginBottom: '16px' }}>
                  <label htmlFor="department" style={{fontSize:'15px'}}>Select Service:</label>
                  <select
                className="form-control form-select"
                value={service}
                onChange={(e) => setservice(e.target.value)}
                aria-label="Default select example"
               style={{ width: '100%', padding: '8px', fontSize: '14px' }}
              >
                <option value="">Select Service</option>
                {servicelist.map((ser) => (
                  <option key={ser.id} value={ser.id}>
                    {ser.service_name}
                  </option>
                ))}
              </select>
                </div>

    { sourcelist.map((item, index) => (
  <div key={index} className="row">
    <div className="col-md-3">
      <label style={{fontSize:'15px'}}>{item.category.category_name}   (Enter Value In Percent)</label>
      <input type="Number" className="form-control"  onChange={(e) => calculate_category_data(e.target.value, item.category.id,null)} />
    </div>
    <div className="col-md-1">
    { total !== '' && ( 
          <div style={{color:'red'}}>{calculate_percentage(categoryValues[item.category.id], parseFloat(total))}</div>
    )}
    </div>
    
    <div className="col-md-3">
      <label style={{fontSize:'15px'}}>{item.subattributes[0].name}   (Enter Value In Percent)</label>
      <input type="Number" className="form-control" onChange={(e) => calculate_category_data(e.target.value, item.category.id, item.subattributes[0].subattribute_id)} />
    </div>
    <div className="col-md-3">
      <label style={{fontSize:'15px'}}>{item.subattributes[1].name}  (Enter Value In Percent)</label>
       <input type="Number" className="form-control" onChange={(e) => calculate_category_data(e.target.value, item.category.id, item.subattributes[1].subattribute_id)} />
    </div>
  </div>
))}
</div>
}
 {target_type ==='department_based' &&
 <div style={{ marginBottom: '16px' }}>
                  <label htmlFor="department" style={{fontSize:'15px'}}>Select Group(select group if assign group based target):</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedgroup}
                    onChange={(e) => setselectedgroup(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Group</option>
                    {group_list.map(grp => (
                      <option key={grp.group_id} value={grp.group_id}>
                        {grp.name}
                      </option>
                    ))}
                  </select>
                </div>
      }
       {target_type ==='department_based' &&
              <div>
              <label style={{fontSize:'15px'}}>Select Value Type</label>&nbsp;&nbsp;&nbsp;
               <label>
              <input
               type="radio"
               value="Numbers"
               checked={selectedvaluetype === 'numbers'}
               onChange={handlevaluetypechange}
               
               />
                Numbers
             </label>
             &nbsp;&nbsp;&nbsp;

             <label>
              <input
               type="radio"
               value="text"
               checked={selectedvaluetype === 'text'}
               onChange={handlevaluetypechange}
               
               />
                Text
             </label>
              &nbsp;&nbsp;&nbsp;


              <label>
              <input
               type="radio"
               value="percent"
               checked={selectedvaluetype === 'percent'}
               onChange={handlevaluetypechange}
               
               />
                Percent
             </label>

              </div>
        }

    {target_type === 'department_based' && goalsub_subattribute.map((row, index) => (
  index % 4 === 0 && (
    <div key={index} className="row">
      {[0,1,2,3].map(i => (
        goalsub_subattribute[index + i] && (
          <div key={goalsub_subattribute[index + i].id} className="col-md-6"> {/* Adjusted for 2 columns per row */}
            <label htmlFor={goalsub_subattribute[index + i].id}>{goalsub_subattribute[index + i].name}</label>
            {type_value === 'number' ? (
              <input
                id={goalsub_subattribute[index + i].id}
                value={inputValues[goalsub_subattribute[index + i].id] || ''}
                onChange={e => handleInputChange(e, goalsub_subattribute[index + i].id)}
                className="form-control"
                type="number"
                required
                style={{ marginBottom: '8px' }}
              />
            ) : (
              <textarea
                id={goalsub_subattribute[index + i].id}
                value={inputValues[goalsub_subattribute[index + i].id] || ''}
                onChange={e => handleInputChange(e, goalsub_subattribute[index + i].id)}
                className="form-control"
                required
                style={{ marginBottom: '8px', height: '100px' }} 
              />
            )}
            {total !== '' && (
              <span style={{ color: 'green' }}>
                {calculatePercentage(inputValues[goalsub_subattribute[index + i].id], parseFloat(total), goalsub_subattribute[index + i].id)}
              </span>
            )}
          </div>
        )
      ))}
    </div>
  )
))}


                    {target_type ==='department_based' &&

                    <div className="col-md-12">
                      <input type="checkbox" id="categoryview" checked={showtotal} onChange={(e) => toggleShowdata(e.target.checked)}/>&nbsp; <span style={{color:'red',fontSize:'14px'}}>Are You Want To Divide Above Target Into Monthly Basic</span>
                    </div>
                  }



<div style={{ marginBottom: '16px' }}>
      <label style={{fontSize:'15px'}}>Financial Year:</label>
                      <select id="financialYear"
                         value={financial_year}
                         onChange={(e) => set_financialyear(e.target.value)}
                         style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                        >
                        <option>Select Financial year</option>
                        {financialYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                     ))}
                  </select>
                 </div>
                     <button color="primary" fullWidth className="btn btn-info" onClick={saveData}>
                     Save
                   </button>
                
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}

export default CompanyTargetList;