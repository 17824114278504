import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import Card from "@mui/material/Card";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import { Spinner } from 'react-bootstrap'; // Import Spinner

const ExecutivePackages = () => {
  const navigate = useNavigate();
  //const { exe_id } = useParams();
  const { sales, tasks } = reportsLineChartData;
  const empId = secureLocalStorage.getItem('emp_id');
  const [loading, setLoading] = useState(true);
  const [product, setproduct] = useState('');
  const [productlist, setproductlist] = useState([]);
  const [servicelist, setservicelist] = useState([]);
  const [service, setservice] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [emp_list, getemp_list] = useState([]);
  const [selectedmanagers, setselectedmanagers] = useState();
  const [sales_data, getsales_data] = useState({});
  const [managersteam, getmanagersteam] = useState([]);
  const [selectedemp, setselectedemp] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const [followup_status, get_followup_details] = useState([]);
  const { details } = useParams();
  const [inner_page_details, get_inner_page_details] = useState([]);
  const [sum, get_sum] = useState();
  const [manager_team, get_manager_team] = useState([]);
  const [checkmanager, setcheckmanager] = useState('');
  const [grouplist, setgrouplist] = useState([]);
  const [group, setgroup] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [category_list, get_category_list] = useState([]);
  const [category, selectedcategory] = useState('');
  const postsPerPage = 10;
  const [package_type, set_package_type] = useState('');
  const [client_name, setclient_name] = useState('');
  const [company_name, setcompany_name] = useState('');
  const [mobile_no, setmobile_no] = useState('');
  const [count, set_count] = useState('');


  useEffect(() => {
    productdetails();
    get_emp_list();
    get_sales_manager_team();
  }, []);

  useEffect(() => {
    get_category_details()
    get_group_category_packages_count();

  }, [product, service,selectedemp])





  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_emp_list = async () => {
    try {
      const endpoint = 'get-sales-managers';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getemp_list(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  const get_category_details = async () => {
    try {
      const response = await dashboardApi.get('count-active-packages-with-category', {
        params: {
          emp_id: empId,
          product: product,
          service: service,
          selected_emp:selectedemp,
          manager:selectedmanagers,
        },
      });
      if (response.data.status === 200) {
        get_category_list(response.data.data);
        set_count(response.data.count);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }

  const get_group_category_packages_count = async () => {
    try {
      const response = await dashboardApi.get('get-group-category-packages-count', {
        params: {
          emp_id: empId,
          product: product,
          service: service,
          selected_emp:selectedemp,
          manager:selectedmanagers,
        },
      });
      if (response.data.status === 200) {
        get_inner_page_details(response.data.data);
        //set_count(response.data.count);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }

  }

  useEffect(() => {
    if (selectedmanagers) {
      setselectedemp([]);

      const fetchData = async () => {
        try {
          const endpoint = `get-all-manager-employee/${selectedmanagers}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            getmanagersteam(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedmanagers]);



  useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);

  const handlePageClick = (selected) => {
    //console.log(selected);
    setCurrentPage(selected.selected);
  };

  const get_sales_manager_team = async()=>{
    try {
     const endpoint = `sales-dashboard-show-manager-team/${empId}`;
     const response = await dashboardApi.get(endpoint);

     if (response.data.status === 200) {
       get_manager_team(response.data.data);
       setcheckmanager(response.data.reporting_manager);
     } else {
       console.error('Error fetching data:', response.data.error);
     }
   } catch (error) {
     console.error('Error fetching data:', error);
   }

 }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>


          <div className="card-body">
            <div className='container'>
              <div className='row'>
                <div className="col-md-3 p-1">
                  <label>Choose Product</label>
                  <select
                    className="form-control form-select"
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                  >
                    <option value="">Select Product</option>
                    {productlist.map((row) => (
                      <option key={row.id} value={row.id}>
                        {row.product_name}
                      </option>
                    ))}
                  </select>

                </div>
                {product &&
                  <div className="col-md-3  p-1">
                    <label>Choose Service</label>
                    <select
                      className="form-control form-select"
                      value={service}
                      onChange={(e) => setservice(e.target.value)}
                      aria-label="Default select example"
                      style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                    >
                      <option value="">Select Service</option>
                      {servicelist.map((ser) => (
                        <option key={ser.id} value={ser.id}>
                          {ser.service_name}
                        </option>
                      ))}
                    </select>
                  </div>

                }
                {empId === 'RIMS1' && (
               <>
             <div className="col-sm-3 col-6 p-1">
             <label>Select Managers</label>
                <select
                  className="form-control form-select"
                  value={selectedmanagers}
                  onChange={(e) => setselectedmanagers(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Managers</option>
                   {emp_list.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                     {row.emp_fname} {row.emp_lame}
                    </option>
                    ))}
                  </select>
                  </div>

                  <div className="col-sm-3 col-6 p-1">
                  <label>Select Team</label>
                <select
                  className="form-control form-select"
                  value={selectedemp}
                  onChange={(e) => setselectedemp(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Team</option>
                   {managersteam.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                     {row.emp_fname} {row.emp_lame}
                    </option>
                    ))}
                  </select>
                  </div>
                 </>
                 )}

               {checkmanager === 1 && (
                <div className="col-sm-3 col-6 p-1">
                   <label>Select Team</label>
                <select
                  className="form-control form-select"
                  value={selectedemp}
                  onChange={(e) => setselectedemp(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Team</option>
                   {manager_team.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                     {row.emp_fname} {row.emp_lame}
                    </option>
                    ))}
                  </select>
                  </div>

               )}
                {service &&
                  <div className="col-md-3  p-1">
                    <h6 style={{ color: 'red' }}>Total Active Packages : {count}</h6>

                  </div>
                }

                {service &&
                  <table className="table table-bordered  table-responsive" style={{ display: "inline-table" }}>
                    <thead>
                      <tr>
                        <td>Group</td>
                        {category_list.map((category) => (
                          <td key={category.id}>{category.category_name}</td>
                        ))}
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                  {Object.keys(inner_page_details).map((city, index) => {
                  const cityTotalCount = inner_page_details[city].reduce(
                 (sum, data) => sum + (data.total_count || 0),
                 0
               );

    return (
      <tr key={index}>
        <td style={{ fontSize: '12px' }}>{city}</td>

        {category_list.map((category) => {
          const matchingData = inner_page_details[city].find(
            (data) => data.category_id == category.id
          );

          const totalCount = matchingData ? matchingData.total_count : 0;

          return (
            <td key={category.id}>
              {totalCount}
            </td>
          );
        })}

        <td style={{ fontWeight: 'bold' }}>{cityTotalCount}</td>
      </tr>
    );
  })}
</tbody>

                  </table>
                }
              </div>
            </div>

          </div>

        </Card>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default ExecutivePackages;
