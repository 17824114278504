import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';

import ReportsDetailsHeader from 'layouts/common_components/reports_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';

import axios from 'axios';
import { saveAs } from 'file-saver';

function Enquiry_report()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enqReports, setEnqReports] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Start from 0 for zero-based index
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [selectFromDate, setFilterDateFrom] = useState('');
  const [selectToDate, setFilterDateTo] = useState('');
  const [dateError, setError] = useState('');
  const [productList, setProductList] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [serviceList, setServiceList] = useState();
  const [selectedService, setSelectedService] = useState();

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();

  const [selectedEnqStatus, setEnqStatus] = useState();
  const [selectedEnqType, setSelectedEnqType] = useState(1);

  

  useEffect(() => {
    getExeDetails();
    getProductList();
    getGroupList();
  }, []);

  useEffect(() => {
    getEenquiryReports();
  }, [currentPage])

  const handleFromDateChange = (e) => {
    setFilterDateFrom(e.target.value);
    validateDates(e.target.value, selectToDate);
  };

  const handleToDateChange = (e) => {
    setFilterDateTo(e.target.value);
    validateDates(selectFromDate, e.target.value);
  };

  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = parseISO(from);
      const toDateObj = parseISO(to);

      if (isBefore(toDateObj, fromDateObj)) {
        setError('To date cannot be before from date.');
        setFilterDateTo('');
      } else {
        setError('');
      }
    }
  };

  const getEenquiryReports = async () => {
    setLoading(true);
    try {
      const response = await dashboardApi.get(`get-enquiry-reports`, {
        params: {
          emp_id: emp_id,
          from_date: selectFromDate,
          to_date: selectToDate,
          per_page: postPerPage,
          group: selectedGroup,
          product: selectedProduct,
          enq_status: selectedEnqStatus,
          enq_type:selectedEnqType,
          page: currentPage + 1,
        }
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const exeData = responseData.data || responseData;
        setEnqReports(exeData);
        setNumber(response.data.last_page);
        setFilterDateFrom(response.data.from_date);
        setFilterDateTo(response.data.to_date);
        setSelectedGroup(response.data.group);
        setSelectedProduct(response.data.product);
        setEnqStatus(response.data.enq_status);
        setLoading(false);
      } else {
        console.error('Error fetching reports:', response.data.message);
      }

    } catch (error) {
      console.error('Error fetching reports:', error);
      setLoading(false);
    }
  }


  const downloadCSV = async()=>
  {
    const ipAddress = await getIpAddress();
    const deviceInfo = getDeviceInfo();
    try {
      const response = await dashboardApi.get('get-enquiry-csv-reports', {
        params: 
        {
          emp_id: emp_id,
          from_date: selectFromDate,
          to_date: selectToDate,
          per_page: postPerPage,
          group: selectedGroup,
          product: selectedProduct,
          enq_status: selectedEnqStatus,
          enq_type:selectedEnqType,
          ipAddress:ipAddress,
          deviceInfo:deviceInfo,
        },
       responseType: 'blob',
    });
      const blob = new Blob([response.data], { type: 'text/csv'});
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `enq_info_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  }

  const getIpAddress = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching IP address', error);
        return null;
    }
  };

  const getDeviceInfo = () => {
    return {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        appVersion: navigator.appVersion,
    };
  };

  const getGroupList = async () => {
    try {
      const response = await dashboardApi.get(`get-group-list`);
      if (response.data.status === 200) {
        setGroupList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching group list:', error);
    }
  }

  const getProductList = async () => {
    try {
      const type = "active";
      const response = await dashboardApi.get(`sales-product-list/${type}`);
      if (response.data.status === 200) {
        setProductList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching product list:', error);
    }
  }

  const getExeDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
      if (response.data.status === 200) {
        setExeDetails(response.data.data);
      } else {
        console.error('Error fetching executive details:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching executive details:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedProduct) {
      const getServiceList = async () => {
        const serviceResponse = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
        if (serviceResponse) {
          setServiceList(serviceResponse.data.data);
        } else {
          console.log('Something went wrong, Please try again.');
        }
      }
      getServiceList();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedService) {
      const getCategoryList = async () => {
        const categoryResponse = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
        if (categoryResponse) {
          setCategoryList(categoryResponse.data.data);
        } else {
          console.log('Something went wrong, Please try again.');
        }
      }
      getCategoryList();
    }
  }, [selectedService]);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <DashboardLayout>
    <DashboardNavbar />
    <MDBox pt={3} pb={3}>
      <Card>
        <div className="card-body p-3 ">
          <ReportsDetailsHeader />
          <div className='d-flex flex-wrap align-items-end'>

            <div className="col-sm-2 col-12">
              <small htmlFor="Enquiry Type">Enquiry Type</small>
              <select className="form-control" value={selectedEnqType} onChange={(e) =>{setSelectedEnqType(e.target.value)}}>
                <option value="1">All</option>
                <option value="2">Sent Enquiry</option>
              </select>
            </div>

            <div className="col-sm-2 col-12">
              <small htmlFor="fromDate">{selectedEnqType==1?'From Date :':'Sent From Date :'}</small>
              <input
                type="date"
                id="fromDate"
                className="form-control"
                onChange={handleFromDateChange}
                value={selectFromDate}
              />
            </div>

            <div className="col-sm-2 col-12">
              <small htmlFor="toDate">{selectedEnqType==1?'To Date :':'Sent To Date:'}</small>
              <input
                type="date"
                className="form-control"
                value={selectToDate}
                onChange={handleToDateChange}
              />
              {dateError && <small className='text-danger d-block'>{dateError}</small>}
            </div>

            <div className="col-sm-2 col-12">
              <small>Select Product</small>
              <select className="form-control" value={selectedProduct} onChange={(e) => { setSelectedProduct(e.target.value) }}>
                <option value="">Select Product</option>
                {productList && productList.map((pro) => (
                  <option key={pro.id} value={pro.id}>{pro.product_name}</option>
                ))}
              </select>
            </div>

            <div className="col-sm-2 col-12">
              <small>Select Group</small>
              <select className="form-control" value={selectedGroup} onChange={(e) => { setSelectedGroup(e.target.value) }}>
                <option value="">Select Group</option>
                {groupList && groupList.map((group) => (
                  <option key={group.group_id} value={group.group_id}>{group.name}</option>
                ))}
              </select>
            </div>

            <div className="col-sm-2 col-12">
              <small>Enquiry Status</small>
              <select className="form-control" value={selectedEnqStatus} onChange={(e) => { setEnqStatus(e.target.value) }}>
                <option value="">Select Status</option>
                <option value="1">Sent</option>
                <option value="3">Not Sent</option>
                <option value="0">Pending</option>
                <option value="2">Duplicate</option>
              </select>
            </div>

            <div className="col-sm-2 col-12">
              <br />
              <button className="btn btn-primary" onClick={getEenquiryReports}>Search</button>
            </div>


          </div>
          <div className='d-flex justify-content-between align-items-center px-2 mt-3'>
            <h5 className='m-0'>Enquiry Reports</h5>
            <button className="btn btn-primary" onClick={downloadCSV}>
              <span class="material-icons-outlined">
                file_download
              </span>
            </button>
          </div>

          {loading ? (
            <p style={{ align: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }} alt="Loading" />
            </p>
          ) : (
            <div className='p-2' style={{ overflowY: "hidden" }}>
              <table className="table table-bordered table-hovered" style={{ marginTop: '20px' }}>
                <tr>
                  <td>S.No</td>
                  <td>Enq ID</td>
                  <td>Customer Name</td>
                  <td>Group Name</td>
                  <td>City From</td>
                  <td>City To</td>
                  <td>Address From</td>
                  <td>Address To</td>
                  <td>Product Name</td>
                  <td>Service Name</td>
                  <td>Category Name</td>
                  <td>OTP Verified</td>
                  <td>CS Verified</td>
                  <td>Enquiry Statu</td>
                  <td>Sent Count</td>
                  <td>Expected Value</td>
                  <td>Sent Value</td>
                  <td>Source Type</td>
                  <td>Source URL</td>
                  <td>Recived Date</td>
                  <td>Event Date</td>
                  <td>Sent Date</td>
                </tr>
                {enqReports && enqReports.map((enq, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{enq.id}</td>
                    <td>{enq.customer_name}</td>
                    <td>{enq.group_name}</td>
                    <td>{enq.city_from}</td>
                    <td>{enq.city_to}</td>
                    <td>{enq.address_from}</td>
                    <td>{enq.address_to}</td>
                    <td>{enq.product_name}</td>
                    <td>{enq.service_name}</td>
                    <td>{enq.category_name}</td>
                    <td>{enq.otp_verified === 1 ? 'Yes' : 'No'}</td>
                    <td>{enq.cs_verified === 1 ? 'Yes' : 'No'}</td>
                    <td>{enq.enq_status === 1 ? 'Sent' : enq.enq_status === 2 ? 'Duplicate' : enq.enq_status === 3 ? 'Not Sent' : 'Pending'}</td>
                    <td>{enq.sent_count}</td>
                    <td>{enq.expected_value}</td>
                    <td>{enq.sent_value}</td>
                    <td>{enq.source_type}</td>
                    <td>{enq.source}</td>
                    <td>{enq.created_date}</td>
                    <td>{enq.event_date}</td>
                    <td>{enq.sent_datetime}</td>
                  </tr>
                ))}
              </table>
              <Pagination className="custom-pagination">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={number}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageLinkClassName={"page-link-style"}
                  breakLinkClassName={"break-link-style"}
                  previousLinkClassName={"prev-link-style"}
                  nextLinkClassName={"next-link-style"}
                  forcePage={currentPage} // Add this line
                />
              </Pagination>
            </div>
          )}

        </div>
      </Card>
    </MDBox>
    <Footer />
  </DashboardLayout>
  );

}

export default Enquiry_report;
