import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate,useParams } from "react-router-dom";
import dashboardApi from "layouts/common_components/apibase_url";
import './emp-leave.css';
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";


function EmpLeave() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const [type, settype] = useState('');
  const [option,setoption] = useState([]);
  const [startdate, setstartdate] = useState('');
  const [enddate, setenddate] = useState('');
  const [days, setdays] = useState('');
  const [message,setmessage] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [leavedata,getleavedata] = useState([]);
  let emp_id = secureLocalStorage.getItem('emp_id');
  let local_value = secureLocalStorage.getItem('emp_id');
  const [confirmation,getconfirmation] = useState('');
  const [confirmationleave,getconfirmationleave] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [empleavedata,getempleavedata] = useState([]);
  const [createleave,setcreateleave] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [openModal, setOpenModal] = useState(false);
  const [postPerPage] = useState(10);
  const [half_day,sethalf_day] = useState('');
   const [currentQuarterStart, setCurrentQuarterStart] = useState("");
  const [currentQuarterEnd, setCurrentQuarterEnd] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [leave_type,set_leave_type] = useState([]);
  const [selectedleave_type,set_selected_leave_type] = useState('');
  const [openrequestmodal,setopenrequestmodal] = useState('');
  const errormessage = {};
  useEffect(() => {
    const today = new Date();
    const currentQuarter = Math.floor(today.getMonth() / 3) + 1;
    const year = today.getFullYear();
    const currentQuarterStartMonth = 3 * (currentQuarter - 1);
    const currentQuarterEndMonth = currentQuarterStartMonth + 2;
    setCurrentQuarterStart(new Date(year, currentQuarterStartMonth, 1));
    setCurrentQuarterEnd(new Date(year, currentQuarterEndMonth + 1, 0));
  }, []);
const formatDate = (date) => {
  if (!(date instanceof Date)) {
    return "";
  }
  const year = String(date.getFullYear()).slice(-2);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};
  const isDateDisabled = (date) => {
    if (
      date < currentQuarterStart ||
      date > currentQuarterEnd
    ) {
      return true;
    }
    return false;
  };


  const model_open = (row) => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setstartdate('');
    setenddate('');
    setdays('');
    setmessage('');
    settype('');
    sethalf_day('');
    setValidationErrors({});

  };
 
    
    //emp_id = id;

  console.log(emp_id);

  useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
   useEffect(()=>{
        emp_leave_data();
    },[emp_id])
    const emp_leave_data= async () => {
    try {
      const response = await dashboardApi.get(`emp-leave-data/${emp_id}`);
      if (response.data.status === 200) {
        getempleavedata(response.data.data);
      } else {
        console.error('Error fetching:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching account:', error);
    }
  };
useEffect(() => {
  if (startdate && enddate && type ) {
    const fetchData = async () => {
      try {
       const response =  await dashboardApi.post('emp-leave-count', {
        start_date:startdate,
        end_date:enddate,
        leave_id:type,
      });
      if (response.data.status === 200) {
          setdays(response.data.data);
        } else {
            settype('');
            //setdays('');
          errormessage.msg = response.data.message;
          setValidationErrors(errormessage);
          
        }
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    fetchData();
  }
}, [startdate,enddate,type]);

 useEffect(() => {
   const fetchData = async () => {
    try {
     // const endpoint = 'active-leave-type'; 
       const endpoint = `active-leave-type/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setoption(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
useEffect(() => {
   const fetchData = async () => {
    try {
      const endpoint = 'active-confirmation-leave'; 
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getconfirmationleave(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = `get-emp-confirmation/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getconfirmation(response.data.data[0]);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

const request_modal = () =>{
  setopenrequestmodal(true);

}

useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = `show-leave-type-based-on-emp-status/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        set_leave_type(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
let total_days = 0;
if(type && half_day == true  && startdate==enddate){
   total_days = 0.5;
}
else{
  total_days = days;
}
  const saveleave = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!type) {
      errors.leave_type = 'Leave Type is required';
    }
    if (!startdate) {
      errors.date_from = 'Start Date is required';
    }
    if (!enddate) {
      errors.date_to = 'End Date is required';
    }
    if (!days) {
      errors.no_of_days = 'Days is required';
    }
    if (!message) {
      errors.message = 'Message is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('leave_type', type);
    formData.append('date_from', startdate);
    formData.append('date_to', enddate);
    formData.append('no_of_days', total_days);
    formData.append('message', message);
    formData.append('emp_id', emp_id);
    formData.append('is_half_day',half_day? 1 : 0);
    setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('save-emp-leave', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
          closeModal();


         fetchleavedetails();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else if(response.data.status === 500) {
           errormessage.msg = response.data.message;
           setValidationErrors(errormessage);
      }

       else {
        errormessage.msg = response.data.message;
        setValidationErrors(errormessage);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred',
      });
    }
    finally {
      setIsSubmitting(false);
    }
  };
}
useEffect(()=>{
        fetchleavedetails();
    },[currentPage])
  const  fetchleavedetails = async () => {
    try {
       const response = await dashboardApi.post('get-leave-list', {
        id: emp_id,
        per_page:postPerPage,
        page: currentPage + 1,
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        getleavedata(employeeData);
        setNumber(response.data.last_page);

      } else {
        console.error("Error fetching employee:", response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee:", error);
      setLoading(false);
    }
  };

const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="container-fluid">
                <div className="row">
                  {empleavedata.map((emp, index) => (
                   <div className="col-md-3">
                     <div className="leave-details">
                     <p style={{fontSize:'12px'}}>{emp.leave_name}</p>
                      <p style={{fontSize:'12px'}}>Total Leave: {emp.total_leave}</p>
                      <p style={{fontSize:'12px'}}>last Year Forward Leave: {emp.previous_year_forward_leave}</p>
                     <p style={{fontSize:'12px'}}>Current Year Leave: {emp.current_year_assign_leave}</p>
                    <p style={{fontSize:'12px'}}>Total Leave Taken: {emp.leave_taken}</p>
                     <p style={{fontSize:'12px'}}>Remaining: {emp.remaining}</p>
                 </div>
                  </div>
                  ))}
              </div>
              </div>
             
              <div className="card-body">

                <div className="col-md-2"style={{float:'right'}}>
                <button className="btn btn-info float-right btn-sm" onClick={model_open}>
                 Apply Leave
               </button>
                </div>
                <div className="col-md-2"style={{float:'right'}}>
                  <Link to ='/leave-request' className="btn btn-info btn-sm">
                   Request For Leave
                  </Link>
                </div>
             
               
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Leave Name</td>
                  <td>Date From</td>
                  <td>Date To</td>
                  <td>No Of Days</td>
                  <td>Message</td>
                  <td>Rejection Reason</td>
                  <td>Status</td>
               </tr>
               {leavedata.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.leave_name}</td>
                  <td>{row.date_from}</td>
                  <td>{row.date_to}</td>
                  <td>{row.no_of_days}</td>
                  <td>{row.message}</td>
                  <td>{row.rejection_reason}</td>
                  <td><button className="btn btn-info btn-sm">{row.status === 1 ? 'Approved' : row.status === 2 ? 'Rejected' : 'Pending'}</button></td>

               </tr>
              ))}

            </table>
             )}
                {leavedata.length > 0 && (
                   <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}

                

              </div>
            </Card>
             <Dialog
        open={openModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'630px'} }}
      >
        <DialogTitle>Apply Leave</DialogTitle>
        <DialogContent>
        {validationErrors.msg && (
                <div className="text-danger" style={{ fontSize: '20px' }}>{validationErrors.msg}</div>
                             )}
          <form>
            <div className="container-fluid">
            <div className="row">
             <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="bussiness name" style={{ fontSize: '16px' }}>Select Leave Type </label>
                           <select
                               className="form-control"
                               value={type}
                               onChange={(e) => settype(e.target.value)}
                              >
                           <option value=''>Select Leave Type</option>
                          {empleavedata.map((options) => (
                            <option key={options.leave_id} value={options.leave_id}>
                               {options.leave_name}
                            </option>
                            ))}
                        </select>
                        
                         {validationErrors.leave_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.leave_type}</div>
                             )}
                        </div>
                      </div>

                        <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Date From{" "} </label>
                          <input
                            type="date"
                            className="form-control"
                            value={startdate}
                            onChange={(e) => setstartdate(e.target.value)}
                            min={formatDate(currentQuarterStart)}
                            max={formatDate(currentQuarterEnd)}
                          />
                          {validationErrors.date_from && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.date_from}</div>
                             )}
                        </div>
                      </div>

            </div>
            <div className="row">
             <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Date To{" "} </label>
                          <input
                            type="date"
                            className="form-control"
                            value={enddate}
                            onChange={(e) => setenddate(e.target.value)}
                             min={formatDate(currentQuarterStart)}
                            max={formatDate(currentQuarterEnd)}
                          />
                          {validationErrors.date_to && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.date_to}</div>
                             )}
                        </div>
                      </div>

          {startdate==enddate && 
          <div className="col-md-6">
           <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Half Day Leave</label>
                          <input
                            type="checkbox"
                            className="form-control"
                            value={half_day}
                            onChange={(e) => sethalf_day(e.target.checked)}

                          />
                        </div>



          </div>
        }

            </div>

            <div className="row">
            <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>No Of Days</label>
                          <input
                            type="text"
                            className="form-control"
                            value={total_days}
                            style={{ width: '100%', padding: '8px', fontSize: '14px' }}

                          />
                        {validationErrors.no_of_days && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.no_of_days}</div>
                             )}
                          
                        </div>

            </div>
             <div class ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Message</label>
                           <textarea rows={4} cols={40} style={{height:'43px'}}
                            value={message}
                            onChange={(e) => setmessage(e.target.value)}
                            className = "form-control"


                           />
                           {validationErrors.message && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.message}

                           </div>
                             )} 
                    </div>
                    </div>




           </div>
           
          </form>
        </DialogContent>
        <DialogActions>
          <div className="col-md-12 text-center mt-5">
                <button className="btn btn-danger" onClick={closeModal} >
                  Close
                 </button>
                   &nbsp; &nbsp; &nbsp; &nbsp;
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={saveleave}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Applying...' : 'Apply'}
                      </button>
                      </div>
                     </DialogActions>
                     </Dialog>

                     <Dialog
        open={openrequestmodal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'500px'} }}
      >
        <DialogTitle>Send Request For Leave Assign</DialogTitle>
        <DialogContent>
        {validationErrors.msg && (
                <div className="text-danger" style={{ fontSize: '20px' }}>{validationErrors.msg}</div>
       )}<form>
            <div className="container-fluid">
            <div className="row">
             <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="bussiness name" style={{ fontSize: '16px' }}>Select Leave Type </label>
                           <select
                               className="form-control"
                               value={type}
                               onChange={(e) => settype(e.target.value)}
                              >
                           <option value=''>Select Leave Type</option>
                          {leave_type.map((options) => (
                            <option key={options.id} value={options.leave_name}>
                               {options.leave_name}
                            </option>
                            ))}
                        </select>
                        
                         {validationErrors.leave_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.leave_type}</div>
                             )}
                        </div>
                      </div>
                      </div>

                       <div class ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Remark</label>
                           <textarea rows={4} cols={40} style={{height:'43px'}}
                            value={message}
                            onChange={(e) => setmessage(e.target.value)}
                            className = "form-control"
                           />
                           {validationErrors.message && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.message}

                           </div>
                             )} 
                    </div>
                    </div>
                    </div>
           
          </form>
        </DialogContent>
        <DialogActions>
          <div className="col-md-12 text-center mt-5">
                <button className="btn btn-danger" onClick={closeModal} >
                  Close
                 </button>
                   &nbsp; &nbsp; &nbsp; &nbsp;
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={saveleave}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'submiting...' : 'Submit'}
                      </button>
                      </div>
                     </DialogActions>
                     </Dialog>


          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmpLeave;