import EmployeeDetails from "layouts/employee_details/components";
import BasicInfo from "layouts/employee_details/components/basic_info";
import DocumentInfo from "layouts/employee_details/components/document_details";
import AccountInfo from "layouts/employee_details/components/account_details";
import SallaryInfo from "layouts/employee_details/components/sallary_details";
import EditBasicInfo from "layouts/employee_details/components/edit_basic_info";
import EditempDocument  from "layouts/employee_details/components/edit-emp_document";
import AddLeave from "layouts/leave_details/add-leave";
import EditLeave from "layouts/leave_details/edit-leave";
import LeaveDetails from "layouts/leave_details";
import ModulePermission from "layouts/employee_details/components/module_permission";
import ChangePassword from "layouts/employee_details/components/change_password";
import AddWorkingDay from "layouts/employee_details/components/create-working-day";
import WorkingDay from "layouts/employee_details/components/working-day-list";
import EditWorkingDay from "layouts/employee_details/components/edit-working-day";
import FollowupDetails from "layouts/employee_details/components/followupDetails";
import  secureLocalStorage  from  "react-secure-storage";
import Addmanager from "layouts/reporting_manager";
import Leavepermission from "layouts/leave_permission";
import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import Attendance_Report from "layouts/employee_details/components/attendance_report";
const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];
const add_employee = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-employee");
const edit_employee = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-employee");
const emp_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "employee-list");
const document_details = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "document-details");
const account_info = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "account-info");
const salary_details = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "salary-details");
const add_holiday = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-holiday");
const edit_holiday = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-holiday");
const holiday_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "holiday-list");
const module_permission = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "module-permission");
const add_working_day = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-working-day");
const edit_working_day = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-working-day");
const working_day_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "working-day-list");
const view_team = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "view-team");
const view_team_wfh = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "team-wfh-list");
const create_roster = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-roster");
const team_leave = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "team-leave-request");
const roster_change_request = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "roster-change-request");
let nested = [];
if(emp_list){
nested.push({
  type: "collapse",
  name: "Employee Details",
  key: "Employee Details",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/employee-list",
  component: <EmployeeDetails/>,
});
}

if(holiday_list){
 nested.push({
  type: "collapse",
  name: "Leave Details",
  key: "Leave Details",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/leave-list",
  component: <LeaveDetails/>,
});
}

if(working_day_list){
nested.push({
  type: "collapse",
  name: "Working Day",
  key: "Working Day",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/working-day-list",
  component: <WorkingDay/>,
  nested:[],
});
}
if(working_day_list){
  nested.push({
    type: "collapse",
    name: "Followup Attribute",
    key: "Followup Attribute",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/followup-attribute",
    component: <FollowupDetails/>,
    nested:[],
  });
  }

  nested.push({
    type: "collapse",
    name: "Change Reporting",
    key: "Change Reporting",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/reporting-info",
    component: <Addmanager/>,
    nested:[],
  });

  nested.push({
    type: "collapse",
    name: "Attendance Report",
    key: "",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/attendance-report",
    component: <Addmanager/>,
    nested:[],
  });



const employeeroute = [
  {
    type: "collapse",
    name: "HR-Admin",
    key: "HR-Admin",
    icon: <Icon fontSize="small">settings</Icon>,
    nested:nested
  },
  // {
  //   type: "collapse",
  //   name: "Change Reporting",
  //   key: "Change Reporting",
  //   icon: <Icon fontSize="small">settings</Icon>,
  //   route: "/reporting-info",
  //   component: (token!=null) ? <Addmanager/> : <SignIn/>,
  //   nested:[],
  // },
  {
    route: "/leave-permission/:id",
   component: (token!=null) ? <Leavepermission/> : <SignIn/> ,

  }
 

];
if(add_employee){
employeeroute.push({ 
 route: "/basic-info",
 component: (token!=null) ? <BasicInfo/> : <SignIn/>,
 });
}
employeeroute.push({ 
  route: "/reporting-info",
  component: (token!=null) ? <Addmanager/> : <SignIn/>,
  });


if(document_details){
employeeroute.push({
 route: "/edit-documents/:id",
 component: (token!=null) ? <EditempDocument/> : <SignIn/> ,
});
}
if(document_details){
employeeroute.push({
 route: "/document-info/:id",
 component: (token!=null) ? <DocumentInfo/> : <SignIn/>,
});
}
if(account_info){
 employeeroute.push({
  route: "/account-info/:id",
   component: (token!=null)  ? <AccountInfo/> : <SignIn/>,
});
}
if(salary_details){
 employeeroute.push({
   route: "/sallary-info/:id",
   component: (token!=null) ? <SallaryInfo/> : <SignIn/>,
});
}
if(add_holiday){
 employeeroute.push({
    route: "/add-leave",
    component: (token!=null) ? <AddLeave/> : <SignIn/> ,
});
}
if(edit_holiday){
 employeeroute.push({
    route: "/edit-leave/:id",
    component: (token!=null) ? <EditLeave/> : <SignIn/> ,
});
}
if(holiday_list){
 employeeroute.push({
    route: "/leave-list",
    component: (token!=null) ? <LeaveDetails/> : <SignIn/> ,
});
}

if(add_working_day){
employeeroute.push({
    route: "/create-working-day",
    component: (token!=null) ? <AddWorkingDay/> : <SignIn/> ,

});
}
if(edit_working_day){
employeeroute.push({
    route: "/edit-working-day/:id",
    component: (token!=null) ? <EditWorkingDay/> : <SignIn/>,

});
}
if(working_day_list){
employeeroute.push({
     route: "/working-day-list",
     component: (token!=null) ?  <WorkingDay/> : <SignIn/>,

});
}
if(working_day_list){
  employeeroute.push({
       route: "/followup-attribute",
       component: (token!=null) ?  <FollowupDetails/> : <SignIn/>,
  
  });
  }

  employeeroute.push({
    route: "/attendance-report",
    component: (token!=null) ?  <Attendance_Report/> : <SignIn/>,

});

if(edit_employee){
employeeroute.push({
   route: "/edit-employee/:id",
   component: (token!=null) ? <EditBasicInfo/> : <SignIn/> ,

});
}
if(emp_list){
employeeroute.push({
   route: "/employee-list",
  component: (token!=null) ? <EmployeeDetails/> : <SignIn/> ,

});
}
if(module_permission){
employeeroute.push({
   route: "/module-permission/:id",
   component: (token!=null) ? <ModulePermission/> : <SignIn/> ,

});
}









export default employeeroute;
