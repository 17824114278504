// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Pagination from "react-bootstrap/Pagination";
import ReactPaginate from "react-paginate";

function AssignTargetListSales() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem("emp_id");
  const dept_id = secureLocalStorage.getItem("department_id");
  const token = secureLocalStorage.getItem("token");
  const [targetlist, setTargetList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [financialYears, setFinancialYears] = useState([]);
  const [financial_year, setFinancialYear] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  // Fetch target data
  const fetchTarget = async (page = 0) => {
    try {
      setLoading(true);
      const response = await dashboardApi.get("target-assign-list-for-sales", {
        params: {
          financial_year: financial_year,
          emp_id: emp_id,
          page: page + 1, 
        },
      });

      if (response.data.status === 200) {
        const responsedata = response.data.data;
        const target_data = responsedata.data || responsedata;
        setTargetList(target_data);
        setNumber(response.data.last_page);
      } else {
        console.error("Error fetching data:", response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTarget(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (financial_year) {
      setCurrentPage(0); 
      fetchTarget(0);
    }
  }, [financial_year]);

  useEffect(() => {
    const getCurrentFinancialYear = () => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
      const financialYearEnd = financialYearStart + 1;

      return `${financialYearStart}-${financialYearEnd}`;
    };

    const generateFinancialYears = () => {
      const currentFinancialYear = getCurrentFinancialYear();
      const nextFinancialYears = [];

      for (let i = 1; i <= 5; i++) {
        const startYear = parseInt(currentFinancialYear.split("-")[0]) + i;
        const endYear = startYear + 1;
        nextFinancialYears.push(`${startYear}-${endYear}`);
      }
      const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
      setFinancialYears(allFinancialYears);
    };

    generateFinancialYears();
  }, []);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="col-sm-3">
                  <select
                    value={financial_year}
                    onChange={(e) => setFinancialYear(e.target.value)}
                    style={{ width: "100%", padding: "8px", fontSize: "14px" }}
                    className="form-control"
                  >
                    <option value="">Select Financial year</option>
                    {financialYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <br />
                <center style={{ color: "red" }}>Lead Target Details</center>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered table-striped table-responsive">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Group</td>
                        <td>Product</td>
                        <td>Category</td>
                        <td>Employee</td>
                        <td>Manager</td>
                        <td>Assign Lead</td>
                        <td>Remark</td>
                        <td>Month</td>
                        <td>Year</td>
                        <td>Achieved</td>
                        <td>Remaining</td>
                      </tr>
                    </thead>
                    <tbody>
                      {targetlist.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1 + currentPage * 10}</td>
                          <td>{row.group}</td>
                          <td>{row.product}</td>
                          <td>{row.category}</td>
                          <td>{row.emp_name}</td>
                          <td>{row.manager_name}</td>
                          <td>{row.value}</td>
                          <td>{row.remark}</td>
                          <td>{row.month}</td>
                          <td>{row.financial_year}</td>
                          <td>{row.acheived}</td>
                          <td>{row.remaining}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {targetlist.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
                <br/>
                <center style={{color:'red'}}>Amount Target Details</center>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AssignTargetListSales;
