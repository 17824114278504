import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import FinanceDetailsHeader from 'layouts/common_components/finance_details_header';
import { TextField } from '@mui/material';
import Button from 'react-bootstrap/Button';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';

function ClientInvoice()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [paymentReports, setPaymentReports] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [postPerPage] = useState(20);
  const [selectFromDate, setFilterDateFrom] = useState('');
  const [selectToDate, setFilterDateTo] = useState('');
  const [dateError, setDateError] = useState('');
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState(1);
  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();

  const [selectedService, setSelectedService] = useState();
  const [service, setService] = useState([]);
  const [loadingDowunload, setLoadingDowunload] = useState(false);

  useEffect(()=>{
    getExeDetails();
    getProduct();
  }, []);

  useEffect(() => {
    getPaymentReports();
  }, [currentPage]);

  const handleFromDateChange = (e) => {
    setFilterDateFrom(e.target.value);
    validateDates(e.target.value, selectToDate);
  };

  const handleToDateChange = (e) => {
    setFilterDateTo(e.target.value);
    validateDates(selectFromDate, e.target.value);
  };

  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = parseISO(from);
      const toDateObj = parseISO(to);

      if (isBefore(toDateObj, fromDateObj)) {
        setDateError('To date cannot be before from date.');
        setFilterDateTo('');
      } else {
        setDateError('');
      }
    }
  };

  const getProduct = async () => {
    try {
      const response = await dashboardApi.get(`sales-product-list/active`);
      if (response.data.status === 200) {
        setProduct(response.data.data);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      const fetchService = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
          if (response.data.status === 200) {
            setService(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchService();
    }
  }, [selectedProduct]);

  const getPaymentReports = async () => {
    try {
      const response = await dashboardApi.get(`get-client-invoice`, {
        params: {
          emp_id,
          from_date: selectFromDate,
          to_date: selectToDate,
          product: selectedProduct,
          service: selectedService,
          per_page: postPerPage,
          page: currentPage + 1,
        }
      });

      if(response.data.status === 200)
      {
        const responseData = response.data.data;
        const exeData = responseData.data || responseData;
        setPaymentReports(exeData);
        setNumber(responseData.last_page);
        setFilterDateFrom(response.data.from_date);
        setFilterDateTo(response.data.to_date);
      }
      else
      {
        console.error('Error fetching payment reports:', response.data.message);
      }
      setLoading(false);
    }
    catch(error)
    {
      console.error('Error fetching payment reports:', error);
      setLoading(false);
    }
  }

  

  const getClientInvoice = async () => {
    setLoadingDowunload(true);
    const ipAddress = await getIpAddress();
    const deviceInfo = getDeviceInfo();
    try
    {
      const response = await dashboardApi.get(`get-client-invoice-download`, {
        params: {
          emp_id,
          from_date: selectFromDate,
          to_date: selectToDate,
          product: selectedProduct,
          service: selectedService,
          per_page: postPerPage,
          ipAddress:ipAddress,
          deviceInfo:deviceInfo,
          page: currentPage + 1,
        },
        responseType: 'blob',
      });

   
        const blob = new Blob([response.data], { type: 'text/csv'});
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `client_invoice_${new Date().toISOString()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoadingDowunload(false);
      
    } catch (error) {
      setLoadingDowunload(false);
      console.error('Error downloading the CSV file', error);
    }
  }

  const getIpAddress = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching IP address', error);
        return null;
    }
  };

  const getDeviceInfo = () => {
    return {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        appVersion: navigator.appVersion,
    };
  };

  const getExeDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
      if (response.data.status === 200) {
        setExeDetails(response.data.data);
      } else {
        console.error('Error fetching executive details:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching executive details:', error);
      setLoading(false);
    }
  }

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <FinanceDetailsHeader />
                  </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between align-items-end">
                  <div className="col-sm-2 d-flex flex-column p-0">
                    <small htmlFor="fromDate">From Date:</small>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-control"
                      onChange={handleFromDateChange}
                      value={selectFromDate}
                    />
                  </div>

                  <div className="col-sm-2 d-flex flex-column p-0">
                    <small htmlFor="toDate">To Date:</small>
                    <input
                      type="date"
                      id="toDate"
                      className="form-control"
                      onChange={handleToDateChange}
                      value={selectToDate}
                    />
                    {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                  </div>
                  <div className="col-sm-2 d-flex flex-column p-0">
                    <small>Salect Product</small>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                    
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-2 d-flex flex-column p-0">
                      <small>Select Service</small>
                      <select className="form-control"
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                        >
                        <option value=''>Select Service</option>
                        {service.map((ser) => (
                        <option key={ser.id} value={ser.id}>
                        {ser.service_name}
                        </option>
                        ))}
                      </select>
                  </div>
                  
                  )}

                  <div className="col-sm-2 d-flex flex-column p-0">
                    <br />
                    <Button className="btn btn-primary" onClick={getPaymentReports}>Search</Button>
                  </div>
                  {loadingDowunload ? (
                    
                      <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
                    
                    ) : (
                     <span className="material-icons-outlined btn btn-sm btn-info" onClick={getClientInvoice}>
                      file_download
                    </span>
                    )}
                 </div>

                {loading ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }} alt="Loading..." />
                  </div>
                ) : (
                  <div style={{ overflowY: "hidden", marginTop: '20px' }}>
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Invoice Number</th>
                          <th>Client Name</th>
                          <th>Business Name</th>
                          <th>Product Name</th>
                          <th>Service Name</th>
                          <th>Package Type</th>
                          <th>Invoice Amount</th>
                          <th>Genrated Date</th>
                          <th>Genrated By</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentReports && paymentReports.map((pr, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{display:'inline flex',width:'200px'}}>{pr.invoice_number}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                                <a target="_blank" style={{ margin: '5px' }} href={'https://apiworkforce.r-ims.com/'+ pr.invoice_number}>
                                <span class="material-icons-outlined">
                                  picture_as_pdf
                                </span>
                              </a>
                            </td>
                            <td>{pr.client_name}</td>
                            <td>{pr.business_name}</td>
                            <td>{pr.product_name}</td>
                            <td>{pr.service_name}</td>
                            <td>{pr.payment_for}</td>
                            <td>{pr.paid_amount}</td>
                            <td>{format(parseISO(pr.created_date), 'yyyy-MM-dd')}</td>
                            <td>{pr.created_by_name}</td>
                            <td>{pr.invoice_status==1?<span style={{color:'green'}}>Created</span>:invoice_status==2?<span style={{color:'red'}}>Cancel</span>:'Pending'}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="12">
                            <Pagination className="custom-pagination">
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={number}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                pageLinkClassName={"page-link-style"}
                                breakLinkClassName={"break-link-style"}
                                previousLinkClassName={"prev-link-style"}
                                nextLinkClassName={"next-link-style"}
                                forcePage={currentPage}
                              />
                            </Pagination>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ClientInvoice;
