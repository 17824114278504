// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
//import StockDetailsHeader from "layouts/common_components/stock_details_header";

function AssignTarget() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
  const token = secureLocalStorage.getItem('token');
  const [targetlist, settargetlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [financialYears, setFinancialYears] = useState([]);
  const [financial_year,set_financialyear] = useState('');
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(() => {
    const fetchtarget = async () => {
    try {
      const response = await dashboardApi.get('assign-target-list', {
        params: {
            emp_id:emp_id,
        }
     });

      if (response.data.status === 200) {
        settargetlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  fetchtarget();
}, []);
useEffect(() => {
  if (financial_year) {
    //alert('this is alert');
      const fetchtarget = async () => {
          try {
              const response = await dashboardApi.get('assign-target-list', {
                  params: {
                      financial_year:financial_year,
                      emp_id: emp_id,
                  }
              });
              if (response.data.status === 200) {
                    settargetlist(response.data.data);
              } else {
                  console.error('Error fetching data:', response.data.error);
              }
              setLoading(false);
          } catch (error) {
              console.error('Error fetching data:', error);
              setLoading(false);
          }
      };
      fetchtarget();
  }
}, [financial_year,emp_id]);

useEffect(() => {
  const getCurrentFinancialYear = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
    const financialYearEnd = financialYearStart + 1;

    return `${financialYearStart}-${financialYearEnd}`;
  };

  const generateFinancialYears = () => {
    const currentFinancialYear = getCurrentFinancialYear();
    const nextFinancialYears = [];

    for (let i = 1; i <= 5; i++) {
      const startYear = parseInt(currentFinancialYear.split('-')[0]) + i;
      const endYear = startYear + 1;
      nextFinancialYears.push(`${startYear}-${endYear}`);
    }
    const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
    setFinancialYears(allFinancialYears);
  };

  generateFinancialYears();
}, []);
//console.log(targetlist);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                
                <div className="col-sm-3">
                      <select
                         value={financial_year}
                         onChange={(e) => set_financialyear(e.target.value)}
                         style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                         className='form-control'
                        >
                        <option value ="">Select Financial year</option>
                        {financialYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                     ))}
                  </select>
                

                </div>
                <div style={{float:'right'}}>
                </div>
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered table-striped">
                 <tr>
                  <td>S.No</td>
                  <td>Group</td>
                  <td>Product</td>
                  <td>Category</td>
                  <td>Employee</td>
                  <td>Source</td>
                  <td>Assign Lead</td>
                  <td>Remark</td>
                  <td>Month</td>
                  <td>Year</td>
                  <td>Acheived</td>
               </tr>
               {targetlist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.group}</td>
                  <td>{row.product}</td>
                  <td>{row.category}</td>
                  <td>{row.emp_fname} {row.emp_lname}</td>
                  <td>{row.source}</td>
                  <td>{row.value}</td>
                  <td>{row.remark}</td>
                  <td>{row.month}</td>
                  <td>{row.financial_year}</td>
                  <td>{row.acheived}</td>
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AssignTarget;