import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './dashboard-main-page.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
//import { Bar } from 'react-chartjs-2';
//import totalcollection from '../assets/images/totalcollection.png';
const SuperAdminDashboard = () => {
  const { sales } = reportsLineChartData;
  const [productlist, setProductlist] = useState([]);
  const empId = secureLocalStorage.getItem('emp_id');
  const [bardata, setbardata] = useState([]);
  const [show_count, get_count] = useState({});
  const [followup_count, set_followup_count] = useState([]);
  const [emp_count, get_emp_count] = useState([]);


  useEffect(() => {
    get_data();

  }, [])

  useEffect(() => {
    bar_chart_data();
    get_followup_data();
    bar_chart_data_show_employee_count()

  }, [empId])

  const prepareChartData = () => {

    const labels = bardata.map(item => item.month);
    const data = bardata.map(item => item.total_collection);
    const get_year = bardata.map(item => item.year);

    //console.log(labels);

    return {
      labels: labels,
      datasets: [
        {
          label: 'Monthly Sales',
          data: data,
          backgroundColor: 'green',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    }
  }


  const followupdatacounta = () => {

    const labels = followup_count.map(item => item.month);
    const data = followup_count.map(item => item.total_payment_followup);
    const get_year = followup_count.map(item => item.year);

    //console.log(labels);

    return {
      labels: labels,
      datasets: [
        {
          label: 'Monthly payment Followup',
          data: data,
          backgroundColor: 'blue',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    }
  }

  const bar_chart_data = async () => {
    try {
      const response = await dashboardApi.get('get-total-collection', {
        params: {
          emp_id: empId,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setbardata(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const bar_chart_data_show_employee_count = async () => {
    try {
      const response = await dashboardApi.get('hr-dashboard-emp-count-graph')
      if (response.data.status === 200) {
        const responseData = response.data.data;
        // console.log(responseData);
        get_emp_count(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const get_followup_data = async () => {
    try {
      const response = await dashboardApi.get('get-monthly-payment-followups', {
        params: {
          emp_id: empId,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        set_followup_count(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const get_data = async () => {
    try {
      const endpoint = 'show-data-on-superadmin-dashboard';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        get_count(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const prepareChartData_of_emp_count = () => {

    const labels = emp_count.map(item => item.year);
    const data = emp_count.map(item => item.count);
    //const get_year = bardata.map(item => item.year);



    return {
      labels: labels,
      datasets: [
        {
          label: 'Employee Count Yearly Basis',
          data: data,
          backgroundColor: 'blue',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <div>
          <div className='d-flex flex-wrap mb-2'>
            <div className='p-1 col-sm-3 col-12 d-flex flex-column'>
              <Link className='dashboard-category-main-card' to="/sales-dashboard"> 
                  <strong>Sales Dashboard</strong>
               </Link>
            </div>
            <div className='p-1 col-sm-3 col-12 d-flex flex-column'>
              <Link className='dashboard-category-main-card' to="/cs-dashboard"> 
                  <strong>CS Dashboard</strong>
              </Link>
            </div> 
            <div className='p-1 col-sm-3 col-12 d-flex flex-column'>
              <Link className='dashboard-category-main-card' to="/digital-dashboard"> 
                  <strong>D.M Dashboard</strong>
              </Link>
            </div>  
            <div className='p-1 col-sm-3 col-12 d-flex flex-column'>
              <Link className='dashboard-category-main-card' to="/hr-dashboard"> 
                  <strong>HR Dashboard</strong>
              </Link>
            </div>   
          </div>
          <div className="d-flex flex-wrap">
            <div className='col-sm-2 col-6 p-1'>
              <div className="card align-items-center px-2 py-3">
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/totalcollection.png`} style={{ height: '60px' }} alt="group loss" />
                <div class="text-center mt-3">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Collection</h5>
                  <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{show_count.collection}</h5>
                </div>
              </div>
            </div>

            <div className='col-sm-2 col-6 p-1'>
              <div className="card align-items-center px-2 py-3">
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/forcast.png`} style={{ height: '60px' }} alt="group loss" />
                <div class="text-center mt-3">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Follow Ups</h5>
                  <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{show_count.followups}</h5>
                </div>
              </div>

            </div>

            <div className='col-sm-2 col-6 p-1'>
              <div className="card align-items-center px-2 py-3">
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/renewal.png`} style={{ height: '60px' }} alt="group loss" />
                <div class="text-center mt-3">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>New/Renew Sales</h5>
                  <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{show_count.new_sale}/{show_count.renew}</h5>
                </div>
              </div>
            </div>

            <div className='col-sm-2 col-6 p-1'>
              <div className="card align-items-center px-2 py-3">
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/renewal.png`} style={{ height: '60px' }} alt="group loss" />
                <div class="text-center mt-3">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Lead Adwords</h5>
                  <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{show_count.adword}</h5>
                </div>
              </div>
            </div>
            <div className='col-sm-2 col-6 p-1'>
              <div className="card align-items-center px-2 py-3">
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/renewal.png`} style={{ height: '60px' }} alt="group loss" />
                <div class="text-center mt-3">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Lead Organic</h5>
                  <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{show_count.organic}</h5>
                </div>
              </div>
            </div>

            <div className='col-sm-2 col-6 p-1'>
              <Link className='h-100'
                to='/org-ticket-list'
              >
                <div className="card align-items-center px-2 py-3">
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/ticket.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Raise Tickets</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{show_count.total_tickets}</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-sm-2 col-6  p-1'>
              <Link className='h-100'
                to='/department-target'
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/organization.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>View DM Targets</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}></h5>
                  </div>
                </div>
              </Link>



            </div>

            <div className='col-sm-2 col-6  p-1'>
              <Link className='h-100'
                to='/sales-lead'
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/organization.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>View Sales Targets</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}></h5>
                  </div>
                </div>
              </Link>



            </div>


          </div>
          <br />
             <div class="d-flex flex-wrap">
              <div class="col-sm-6 col-12 my-3 p-sm-3 p-0">
                <div class="chart-container">
                  <Bar
                    data={prepareChartData()}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div class="col-sm-6 col-12 my-3 p-sm-3 p-0">
                <div class="chart-container">

                  <Bar
                    data={followupdatacounta()}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div class="col-sm-6 col-12 my-3 p-sm-3 p-0">
                <div class="chart-container">

                  <Bar
                    data={prepareChartData_of_emp_count()}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                      },
                    }}
                  />
                </div>
              </div>
          </div>
        </div>

      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default SuperAdminDashboard;