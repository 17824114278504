import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import dashboardApi from "layouts/common_components/apibase_url";
import "./emp-leave.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import secureLocalStorage from "react-secure-storage";

function LeaveRequest() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const navigate = useNavigate();

  const [validationErrors, setValidationErrors] = useState({});
  const [leaveType, setLeaveType] = useState("");
  const [remark, setRemark] = useState("");
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openRequestModal, setOpenRequestModal] = useState(false);

  // Redirect if token is missing
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(()=>{
    fetchLeaveRequests()

  },[emp_id])

  // Fetch leave types
  useEffect(() => {
    const fetchLeaveTypes = async () => {
      try {
        const response = await dashboardApi.get(`show-leave-type-based-on-emp-status/${emp_id}`);
        if (response.data.status === 200) {
          setLeaveTypes(response.data.data);
        } else {
          console.error("Error fetching leave types:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    };
    fetchLeaveTypes();
  }, [emp_id]);

  // Fetch leave requests
    const fetchLeaveRequests = async () => {
      try {
        const response = await dashboardApi.get(`get-leave-assign-request/${emp_id}`);
        if (response.data.status === 200) {
          setLeaveRequests(response.data.data);
          setLoading(false);
        } else {
          console.error("Error fetching leave requests:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leave requests:", error);
      }
    };
  

  const handleOpenRequestModal = () => {
    setOpenRequestModal(true);
  };

  const handleCloseModal = () => {
    setLeaveType("");
    setRemark("");
    setValidationErrors({});
    setOpenRequestModal(false);
  };

  const handleSaveData = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!leaveType) errors.leaveType = "Leave Type is required";
    if (!remark) errors.remark = "Remark is required";
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const formData = new FormData();
        formData.append("leave_type", leaveType);
        formData.append("remark", remark);
        formData.append("emp_id", emp_id);

        const response = await dashboardApi.post("save-leave-assign-request", formData);

        if (response.data.status === 200) {
          Swal.fire({ icon: "success", text: response.data.message });
          handleCloseModal();
          fetchLeaveRequests();
        } else {
          Swal.fire({ icon: "error", text: response.data.message });
        }
      } catch (error) {
        Swal.fire({ icon: "error", text: "An error occurred" });
        console.error("Error saving leave request:", error);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="col-md-4">
              <Link to='/emp-leave' className="btn btn-info btn-sm">Back</Link>

              </div>
              <div className="container-fluid">
                <div className="col-md-2" style={{ float: "right" }}>
                  <button
                    className="btn btn-info float-right btn-sm"
                    onClick={handleOpenRequestModal}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Emp Name</th>
                        <th>Leave Name</th>
                        <th>Remark</th>
                        <th>Request Date</th>
                        <th>Status</th>
                        <th>Action By</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {leaveRequests.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>
                            {row.emp_fname} {row.emp_lame}
                          </td>
                          <td>{row.leave_name}</td>
                          <td>{row.remark}</td>
                          <td>{row.created_date}</td>
                          <td>{row.status_text}</td>
                          <td>
                            {row.approved_by_fname} {row.approved_by_lame}
                          </td>
                         
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>

            <Dialog
              open={openRequestModal}
              fullWidth
              PaperProps={{ style: { maxWidth: "600px", padding: "20px" } }}
            >
              <DialogTitle>Send Request For Leave Assign</DialogTitle>
              <DialogContent>
                <form>
                  <div className="form-group">
                    <label htmlFor="leaveType">Select Leave Type</label>
                    <select
                      className="form-control"
                      value={leaveType}
                      onChange={(e) => setLeaveType(e.target.value)}
                    >
                      <option value="">Select Leave Type</option>
                      {leaveTypes.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.leave_name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.leaveType && (
                      <div className="text-danger">{validationErrors.leaveType}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="remark">Remark</label>
                    <textarea
                      rows={4}
                      className="form-control"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                    {validationErrors.remark && (
                      <div className="text-danger">{validationErrors.remark}</div>
                    )}
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <button className="btn btn-danger" onClick={handleCloseModal}>
                  Close
                </button>
                <button className="btn btn-info" onClick={handleSaveData}>
                  Save
                </button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LeaveRequest;
