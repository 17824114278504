import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CsDetailsHeader from 'layouts/common_components/cs_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";


function Customer_feedback() 
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [loading, setLoading] = useState(true);
  const [callConnecting, setCallLoading] = useState(false);
  const [sentEnq,setSentEnq] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [callingMobileNo,setCallingMobileNumber] = useState('');
  const [feedBackModal,modalCustomerFeedback]=useState(false);
  const [leadId,setLeadId] = useState('');
  const [selectedQuoteStatus, setQuoteStatus]=useState('');
  const [validationErrors, setValidationErrors] = useState({});
  
  const [enqFeedbackModal, enquiryFeedbackModal] = useState(false);
  const [enqId,setEnqid]=useState('');
  const [enqStatusDetails, setEnqStatusDetails] = useState([]);
  const [status, setStatus] = useState('');
  const [remarks, setRemarks] = useState('');
  const [followupDate, setFollowupDate] = useState('');
  const [movingDate, setMovingDate] = useState('');
  const [callBackDate, setCallBackDate] = useState('');
  const [customerBudget, setCustomerBudget] = useState('');
  const [feedbackStatus, setFeedbackStatus] = useState('');
  const [clientDetails, setClienDetails]=useState([]);

  const [enqHistory, setEnqHistory]= useState([]);
  
    const onEnqFeedback=(id)=>{
      setEnqid(id);
      enquiryFeedbackModal(true);
    }

    const ofEnqFeedback=()=>{
      enquiryFeedbackModal(false);
    }

    
    const onFeedBackModal=(id)=>{
      setLeadId(id);
      modalCustomerFeedback(true);
    }

    const ofFeedBackModal =()=>
    {
      modalCustomerFeedback(false);
    }

    const handleStatusChange = (e) => {
      setStatus(e.target.value);
    };

    const updateFeedbackComment = async()=>
    {
      const errors = {};
      if(!selectedQuoteStatus)
      {
        errors.selectedQuoteStatus = 'Quote status is required.';
      }
      if (Object.keys(errors).length > 0)
      {
        return; 
      }

      try
      {
        const response = await dashboardApi.get(`update-feedback-comment-client-wise`,{
          params:{
            emp_id:emp_id,
            lead_id:leadId,
            quote_status:selectedQuoteStatus,
          }
        });

        if(response.data.status === 200)
        {
          modalCustomerFeedback(false);
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          getSentEnquiry();
        }
        else
        {
          modalCustomerFeedback(false);
          Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
          });
        }
      }
      catch (error)
      {
        modalCustomerFeedback(false);
        console.error('Error fetching countries:', error);
        //setLoading(false);
      }
    }

    useEffect(() => {
      getSentEnquiry();
      getEnquiryStatus();
    }, [currentPage]);

    const getSentEnquiry = async () => 
    {
      
      try{
        const response = await dashboardApi.get(`get-sent-enquiry`,{
          params:{
            emp_id:emp_id,
            per_page: postPerPage,
            page: currentPage + 1,
          }
          
        });
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setNumber(responseData.last_page);
          setSentEnq(customerData);
          setLoading(false);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
          setLoading(false);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    const getEnquiryStatus = async () => 
    {
      
      try{
        const response = await dashboardApi.get(`get-enquiry-feedback-status`,{
          params:{
            emp_id:emp_id,
          }
        });
        if(response.data.status === 200)
        {
          setEnqStatusDetails(response.data.data);
          
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
          
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    const saveCustomerEngagement = async() => {
      const form = new FormData();
      form.append("emp_id",emp_id);
      form.append("enqId",enqId);
      form.append("status",status);
      form.append("remarks",remarks);
      form.append("followupDate",followupDate);
      form.append("movingDate",movingDate);
      form.append("callBackDate",callBackDate);
      form.append("customerBudget",customerBudget);
      form.append("feedbackStatus",feedbackStatus);

      try {
        const response = await dashboardApi.post('save-enq-feedback-by-cs', form);
        if(response.data.status === 200)
        {
          enquiryFeedbackModal(false);
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          getSentEnquiry();
        }
        else
        {
          enquiryFeedbackModal(false);
          Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
          });
        }
      }
      catch (error)
      {
        enquiryFeedbackModal(false);
        console.error('Error fetching countries:', error);
        //setLoading(false);
      }
    };

    
    const makeCallToCustomer = async (mobile_no,enqId) => 
    {
      setCallingMobileNumber(mobile_no);
      const token = localStorage.getItem('token');
      const form = new FormData();
      form.append("emp_id",emp_id);
      form.append("mobile_number",mobile_no);
      form.append("api_id", "1");
      form.append("enqId", enqId);
      form.append("call_type", 3);
      form.append("secure_key", "d53e5ccd3d0ab16e6c29103beb806bc1");
      try {
        setCallLoading(true);
        
        const response = await dashboardApi.post('dialer/make-a-call', form);
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setCallLoading(false);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
          setCallLoading(false);
        }
      } catch (error) {
        console.error('Error sending POST request', error);
        setCallLoading(false);
      }
    };
      
    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };

    useEffect(() => {
      if(status=='35')
      {
        const getClientlistAcordingToEnqId = async () =>
        {
          try{
            const response = await dashboardApi.get(`get-client-details-by-enqid`,{
              params:{
                emp_id:emp_id,
                enqId: enqId,
              }
              
            });
            if(response.data.status === 200)
            {
              setClienDetails(response.data.data);
              //setLoading(false);
            }
            else
            {
              console.error('Error fetching countries:', response.data.message);
              //setLoading(false);
            }
          }
          catch (error)
          {
            console.error('Error fetching countries:', error);
            //setLoading(false);
          }
        }
        getClientlistAcordingToEnqId();
      }
    }, [enqId,status=='35']);

    useEffect(() =>
    {
      if(enqId)
      {
        const getEnqHistory = async () =>
        {
          try{
            const response = await dashboardApi.get(`get-enq-followup-history-details-by-enqid`,{
              params:{
                emp_id:emp_id,
                enqId: enqId,
              }
              
            });
            if(response.data.status === 200)
            {
              setEnqHistory(response.data.data);
              //setLoading(false);
            }
            else
            {
              console.error('Error fetching countries:', response.data.message);
              //setLoading(false);
            }
          }
          catch (error)
          {
            console.error('Error fetching countries:', error);
            //setLoading(false);
          }
        }
        getEnqHistory();
      }
      
    }, [enqId]);
    
  return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <CsDetailsHeader></CsDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                        <Link className='btn btn-info' target="_blank" to="/add-enquiry">
                            +
                        </Link>
                      </div>
                  </div>
                  {loading ? (
                  <p style={{align:'center'}}>
                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                  </p>
                  ) : (
                  <div style={{overflowY: "hidden"}}>
             
                  <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                    <tr>
                      <td>S.No</td>
                      <td>Enq Id</td>
                      <td>Customer Info</td>
                      <td>From/To city</td>
                      <td>Service/Category</td>
                      <td>Date</td>
                      <td>Verified By</td>
                      <td>Sent To</td>
                      <td>Action</td>
                    </tr>
                    {sentEnq && sentEnq.map((enq,index)=>(
                      <tr>
                        <td>{index + 1}</td>
                        <td>{enq.id}</td>
                        <td>{enq.name} <br/>{enq.primary_no}<br/>{enq.email}</td>
                        <td>{enq.city_from} - {enq.city_to}</td>
                        <td>{enq.product_name}<br/>{enq.service_name} ({enq.category_name})</td>
                        <td>R: {enq.recived_date} <br/> E: {enq.event_date}</td>
                        <td>Otp : {enq.otp_verified==1?'Yes':'No'}<br/> CS: {enq.cs_verified==1?'Yes':'No'}</td>
                        <td>
                          {enq.client_info && enq.client_info.map((clInfo,ind)=>(
                            <tr>
                              <td>{ind +1}</td>
                              <td>{clInfo.business_name} (<span style={{color:'red'}}> {clInfo.quote_status==1?'Sent':clInfo.quote_status==2?'Not Sent':'Pending'} </span>)</td>
                              <td className='d-flex'>
                                <span class="material-icons-outlined btn btn-info btn-sm mx-1" onClick={()=>{onFeedBackModal(clInfo.lead_id)}}>
                                  edit_note
                                </span>
                               
                              </td>

                            </tr>
                        ))}

                        </td>
                       
                        <td className='d-flex'>
                            {callConnecting && callingMobileNo == enq.primary_no ?(
                              <p style={{align:'center'}}>
                              <img className="btn btn-primary btn-sm mx-1" src={`${process.env.PUBLIC_URL}/callConnecting.gif`} style=   {{height:'40px'}}></img>
                                </p>
                            ):(
                              <>
                            <span class="material-icons-outlined btn btn-primary btn-sm mx-1" onClick={()=> {makeCallToCustomer(enq.primary_no,enq.id)}}>
                              call
                            </span>
                            </>
                            )}

                            <span class="material-icons-outlined btn btn-info btn-sm mx-1" onClick={()=>{onEnqFeedback(enq.id)}}>
                              edit_note
                            </span>
                        </td>
                      </tr>
                      ))
                    }
                    <tr>
                      <td colspan="11">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={number}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link-style"}
                            breakLinkClassName={"break-link-style"}
                            previousLinkClassName={"prev-link-style"}
                            nextLinkClassName={"next-link-style"}
                          />
                        </Pagination>
                      </td>
                    </tr>
                  </table>
                </div>
              )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={feedBackModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Update Customer Feedback</DialogTitle>
        <DialogContent style={{ height: "auto" }}>
          <form>
            <div className="row">
              <div className="col-sm-12">
                <label>Quote Status</label>
                <select className="form-control" value={selectedQuoteStatus} onChange={(e)=>{setQuoteStatus(e.target.value)}}>
                  <option value="">Select Status</option>
                  <option value="1">Quotation Sent</option>
                  <option value="2">Quotation Not Sent</option>
                </select>
                {validationErrors.selectedQuoteStatus && (
                  <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedQuoteStatus}</div>
                )}
              </div>
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={ofFeedBackModal}>Close</Link>
          <Link className="btn btn-success btn-sm" onClick={updateFeedbackComment}>Update</Link>
        </DialogActions>
      </Dialog>

      <Dialog open={enqFeedbackModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Enquiry Feedback</DialogTitle>
        <DialogContent style={{ height: "auto" }}>
          <form>
            
                <div className="row">
                  <input type="hidden" name="enq_id" id="enq_id" />
                  <div className="col-sm-12">
                    <label>Select Status</label>
                    <select
                      name="status"
                      id="status"
                      className="form-control"
                      value={status}
                      onChange={handleStatusChange}
                    >
                      <option value="">Select Status</option>
                      {enqStatusDetails && enqStatusDetails.map((enqSt,index)=>(
                     
                        <option value={enqSt.id}>{enqSt.name}</option>
                        
                      ))}
                    
                    </select>
                  </div>

                  {/* Service Provider Selection */}
                  <div
                    className="col-sm-12"
                    id="provider_div"
                    style={{ display: status === '35' ? 'block' : 'none' }}
                  >
                    <label>Select Service Provider</label>
                    <select className="form-control" name="service_provider" id="service_provider">
                      <option value="">Select Client</option>
                      {clientDetails && clientDetails.map((cd)=>(
                          <option value={cd.comp_id}>{cd.business_name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-12">
                    <label>Remarks</label>
                    <input
                      type="text"
                      name="remarks"
                      id="remarks"
                      className="form-control"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </div>

                
                  {status === '32'  && (
                    <div className="col-sm-6">
                      <label>Followup Date/Time</label>
                      <input
                        type="datetime-local"
                        name="followup_date"
                        id="followup_date"
                        className="form-control"
                        value={followupDate}
                        onChange={(e) => setFollowupDate(e.target.value)}
                      />
                    </div>
                  )}

                  {status === '34' && (
                    <div className="col-sm-6">
                      <label>Moving Date/Time</label>
                      <input
                        type="datetime-local"
                        name="moving_date"
                        id="moving_date"
                        className="form-control"
                        value={movingDate}
                        onChange={(e) => setMovingDate(e.target.value)}
                      />
                    </div>
                  )}

                  {status === '40' && (
                    <div className="col-sm-6">
                      <label>Call Back Date/Time</label>
                      <input
                        type="datetime-local"
                        name="call_back_date"
                        id="call_back_date"
                        className="form-control"
                        value={callBackDate}
                        onChange={(e) => setCallBackDate(e.target.value)}
                      />
                    </div>
                  )}

                  {status === '35' && (
                    <div className="col-sm-6">
                      <label>Customer Budget</label>
                      <input
                        type="number"
                        name="customer_budget"
                        id="customer_budget"
                        className="form-control"
                        placeholder="7000"
                        value={customerBudget}
                        onChange={(e) => setCustomerBudget(e.target.value)}
                      />
                    </div>
                  )}

                  {(status === '33' || status ==='35') && (
                    <div className="col-sm-6">
                      <label>Feedback Status</label>
                      <select
                        className="form-control"
                        id="feedback_status"
                        name="feedback_status"
                        value={feedbackStatus}
                        onChange={(e) => setFeedbackStatus(e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value="Good">Good</option>
                        <option value="Very Good">Very Good</option>
                        <option value="Bad">Bad</option>
                        <option value="Very Bad">Very Bad</option>
                        <option value="Average">Average</option>
                        <option value="Excellent">Excellent</option>
                      </select>
                    </div>
                  )}
                  
                  <div className="col-sm-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Status</th>
                          <th>Remarks</th>
                          <th>F/M/C Date</th>
                          <th>Feedback Status</th>
                          <th>Followup Date</th>
                          <th>Followup By</th>
                        </tr>
                      </thead>
                      <tbody id="followup_details">
                        
                        {enqHistory && enqHistory.map((eh,index)=>(
                          <tr>
                            <td>{index + 1}</td>
                            <td>{eh.followup_name}</td>
                            <td>{eh.remarks}</td>
                            <td>{eh.followup_date} {eh.moving_date} {eh.call_back_date}</td>
                            <td>{eh.feedback_status}</td>
                            <td>{eh.created_date}</td>
                            <td>{eh.emp_fname}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
            
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={ofEnqFeedback}>Close</Link>
          <Link className="btn btn-success btn-sm" onClick={saveCustomerEngagement}>Update</Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export default Customer_feedback;
