import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import dashboardApi from "layouts/common_components/apibase_url";
import './attendance_report.css';

function Attendance_Report() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const [empReport, setEmpReport] = useState({});
  const [dates, setDates] = useState([]); 
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState("");
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState("");
  const[department,setdepartment] = useState([]);
  const[selecteddepartment,setselecteddepartment] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    } else {
      getReport();
    }
  }, [token, emp_id, navigate,selectedMonth,selectedYear,selecteddepartment]);

  const years = Array.from({ length: 6 }, (_, i) => currentYear - i);
  const handleChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-active-department';
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
         setdepartment(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const downloadata = async () => {
    //emp_id: empId,

try {
const response = await dashboardApi.get('attendance-report', {
 params: {
   download: 'excel',
    emp_id: emp_id,
    month:selectedMonth,
    year:selectedYear,
    department:selecteddepartment,
  },
 responseType: 'blob',
});

const blob = new Blob([response.data], { type: 'text/csv' });
const downloadUrl = window.URL.createObjectURL(blob);
const a = document.createElement('a');
a.href = downloadUrl;
a.download = `attendance_report_${new Date().toISOString()}.csv`;
document.body.appendChild(a);
a.click();
document.body.removeChild(a);
} catch (error) {
console.error('Error downloading the CSV file', error);
}
};

  const getReport = async () => {
    try {
      const response = await dashboardApi.get("attendance-report", {
        params: {
           emp_id: emp_id,
           month:selectedMonth,
           year:selectedYear,
           department:selecteddepartment,
         },
      });

      console.log("API Response:", response.data); 
      if (response.data.status === 200) {
        if (Array.isArray(response.data.dates)) {
          setDates(response.data.dates); 
        } else {
          console.error("Invalid dates format:", response.data.dates);
          setDates([]); 
        }

        setEmpReport(response.data.data);
      } else {
        console.error("Error fetching attendance report:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching attendance report:", error);
    }
  };
  const calculateAttendanceCounts = (attendance) => {
    let counts = { P: 0, HD: 0, SL: 0, A: 0, L: 0 };

    Object.values(attendance).forEach((status) => {
      if (status === "P") counts.P++;
      else if (status === "HD") counts.HD++;
      else if (status === "SL") counts.SL++;
      else if (status === "A") counts.A++;
      else if (status === "L") counts.L++;
    });

    return counts;
  };

  // const calculateTotalWorkingDays = (totalDays, counts) => {
  //   return totalDays - (counts.HD * 0.5) - counts.A;
  // };

  const handlemonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={30}>
            <Card>
               <div className="col-md-2">
               <button className="btn btn-primary btn-sm" onClick={downloadata}>
                <span class="material-icons-outlined">
                 file_download
                </span>
                </button>

               </div>
               <div className="container-fluid">
               <div className="row">
               <div className="col-md-1">
               <select id="yearDropdown" value={selectedYear} onChange={handleChange} className="form-control">
              <option value="">-- year --</option>
                 {years.map((year) => (
                <option key={year} value={year}>
                 {year}
                </option>
                ))}
              </select>

               </div>
               <div className="col-md-1">
               <select id="monthDropdown" value={selectedMonth} onChange={handlemonthChange} className="form-control">
               <option value="">--month --</option>
               {months.map((month, index) => (
               <option key={index} value={month}>
                    {month}
                  </option>
                ))}
                </select>

               </div>

               <div className = "col-md-1">
                           <select className="form-control"
                           value={selecteddepartment}
                           onChange={(event)=>{
                              setselecteddepartment(event.target.value)}}
                            >
                            <option value=''>Department</option>
                            {department.map((departments) => (
                             <option key={departments.id} value={departments.id}>
                              {departments.department_name}
                              </option>
                              ))}
                              
                          </select>
                     </div>
                    </div>
                    </div>
              

              <MDBox p={3}>
                {dates.length > 0 && Object.keys(empReport).length > 0 ? (
                  <table className="attendencetable" style={{ width: "100%"}}>
                    <thead>
                      <tr>
                        <td >Emp Code</td>
                        <td >Employee</td>
                        <td >Department</td>
                        {dates.map((date, index) => (
                          <td  key={index}>
                            {date.date}
                            <br />
                            {date.day_name.slice(0, 3)}
                          </td>
                        ))}
                        <td >Total Working Days</td>
                        <td >Half Days</td>
                        <td >Short Leave</td>
                        <td >Absents</td>
                        <td >Leave</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(empReport).map(([employeeName, details], index) => {
                        const { attendance } = details;
                        const counts = calculateAttendanceCounts(attendance); 
                        //const totalWorkingDays = calculateTotalWorkingDays(dates.length, counts); 

                        return (
                          <tr key={index}>
                            <td >{details.emp_id}</td>
                            <td >{employeeName}</td>
                            <td >{details.department}</td>
                            {dates.map((date, i) => (
                              <td key={i}>{attendance[date.date] || "-"}</td>
                            ))}
                            <td >{details.total_working_days}</td>
                            <td >{counts.HD}</td>
                            <td >{counts.SL}</td>
                            <td >{counts.A}</td>
                            <td >{counts.L}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p>Loading attendance report...</p>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Attendance_Report;
