import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
//import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";
import './pagination.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function EmployeeDetails() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [Employee, setEmployee] = useState([])
  const [loading, setLoading] = useState(true);
   const permission = secureLocalStorage.getItem('permission');
   const parsedPermission = permission ? JSON.parse(permission) : [];
   const add_emp = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-employee");
   const edit_emp = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-employee");
   const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
  const[department,setdepartment] = useState([]);
  const[selecteddepartment,setselecteddepartment] = useState('');
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);
  const [emp_type,getemp_type] = useState([]);
  const [emp_type_id,sendemp_type_id] = useState('');
  const [name,setname] = useState('');
  const [emp_status,set_emp_status] = useState('');
  const [confirmed_status,emp_confirmed_status] = useState('');
   useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
    useEffect(() => {
    fetchEmployee();
  }, [selecteddepartment, currentPage,name,emp_status,emp_type_id,confirmed_status]);

   
   const fetchEmployee = async () => {
    try {
      const response =  await dashboardApi.post('emp-list', {
        dept_id:selecteddepartment,
        emp_type:emp_type_id,
        name:name,
        per_page: postPerPage,
        page: currentPage + 1,
        emp_status:emp_status,
        confirmed_status:confirmed_status,
        
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
         setEmployee(employeeData);
         setNumber(responseData.last_page);
      } else {
        console.error('Error fetching employee:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employee:', error);
      setLoading(false);
    }
  };
//    useEffect(() => {
//   if (selecteddepartment || emp_type_id || name||emp_status ) {
//     const fetchData = async () => {
//       try {
//        const response =  await dashboardApi.post('emp-list', {
//         dept_id:selecteddepartment,
//         emp_type:emp_type_id,
//         name:name,
//         per_page: postPerPage,
//         page: currentPage + 1,
//         emp_status:emp_status,
//       });
//        if (response.data.status === 200) {
//         const responseData = response.data.data;
//         const employeeData = responseData.data || responseData;
//         setEmployee(employeeData);
//         setNumber(responseData.last_page);
//       }
//       else if (response.data.status === 429) {
          
//           console.log('Too Many Requests. Retrying...');
//           await new Promise(resolve => setTimeout(resolve, 2000)); 
//           fetchData(); 
//         }
//        else {
//         console.error('Error fetching employee:', response.data.message);
//       }
//       setLoading(false);
//       } catch (error) {
//       console.error('Error fetching employee:', error);
//       setLoading(false);
//     }
//     };

//     fetchData();
//   }
// }, [selecteddepartment,emp_type_id,name,emp_status]);
 useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-department';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
       setdepartment(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
useEffect(() => {
   const fetchData = async () => {
    try {
      const endpoint = 'active-employee-type';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
       getemp_type(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
  const toggleStatus = async (empId) => {
  const isConfirm = await Swal.fire({
    title: 'Are you sure?',
    text: 'Are You Want To Change Status!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Change it!'
  }).then((result) => {
    return result.isConfirmed;
  });

  if (!isConfirm) {
    return;
  }

  try {
    const response =  await dashboardApi.get(`employee-status/${empId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse =  await dashboardApi.post('employee-change-status', {
        id: empId,
        status: newStatus,
      });

      if (updateResponse.data.status === 200) {
        setEmployee((prevState) =>
          prevState.map((employeeObj) =>
            employeeObj.emp_id === empId ? { ...employeeObj, emp_status: newStatus } : employeeObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  } catch (error) {
    console.error('Error while toggling status:', error);
  }
};

const deleteemp = async (emp_id) => {
  const isConfirm = await Swal.fire({
    title: 'Are you sure?',
    text: 'Are You Want To Delete!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes!'
  }).then((result) => {
    return result.isConfirmed;
  });

  if (!isConfirm) {
    return;
  }

  try {
    const response =  await dashboardApi.get(`delete-emp/${emp_id}`);
    if (response.data.status === 200) {
      Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
       fetchEmployee();
      } 
     else {
      console.error('Error fetching current status:', response.data.message);
    }
  } catch (error) {
    console.error('Error while toggling status:', error);
  }
};
const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

   
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
               <div className = 'container-fluid'>
                <div className = "row">
                <div className = "col-md-3">
                 <label htmlFor="short name" style={{ fontSize: '16px' }}>Department</label>
                           <select className="form-control"
                           value={selecteddepartment}
                           onChange={(event)=>{
                              setselecteddepartment(event.target.value)}}
                            >
                            <option value=''>Department</option>
                            {department.map((departments) => (
                             <option key={departments.id} value={departments.id}>
                              {departments.department_name}
                              </option>
                              ))}
                              
                          </select>
                     </div>
                    <div className = "col-md-3">
                     <label htmlFor="short name" style={{ fontSize: '16px' }}>Employee Type</label>
                           <select className="form-control"
                           value={emp_type_id}
                           onChange={(event)=>{
                              sendemp_type_id(event.target.value)}}
                            >
                            <option value=''>Emp Type</option>
                            {emp_type.map((row) => (
                             <option key={row.id} value={row.id}>
                              {row.emp_type}
                              </option>
                              ))}
                              
                          </select>
                    </div>
                     <div className = "col-md-3">
                         <label htmlFor="short name" style={{ fontSize: '16px' }}>Employee Status</label>
                           <select className="form-control"
                           value={emp_status}
                           onChange={(event)=>{
                              set_emp_status(event.target.value)}}
                            >
                            <option value=''>Emp Status</option>
                            <option value ='active'>Active</option>
                            <option value ='inactive'>InActive</option>
                              
                          </select>
                         </div>

                      <div className = "col-md-3">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Status(Probation/Confirmed)</label>
                           <select className="form-control"
                           value={confirmed_status}
                           onChange={(event)=>{
                              emp_confirmed_status(event.target.value)}}
                            >
                            <option value=''>Emp Status</option>
                            <option value ='probation'>Probation</option>
                            <option value ='confirmed'>Confirmed</option>
                              
                          </select>
                     </div>
                </div>
                <div className = "row">
                     <div className = "col-md-3">
                        <label htmlFor="short name" style={{ fontSize: '16px' }}>Search Employee</label>
                      <input
                            type="text"
                            className="form-control form-select"
                            placeholder = "search Name"
                            value={name}
                             onChange={(e) => setname(e.target.value)}
                          />
                           
                    </div>
                 
                </div>
                </div>
                {add_emp && (
                <div style={{float:'right'}}>
                   <Link className='btn btn-primary btn btn-sm mb-2 float-end' to={"/basic-info"}>
                   +
                </Link>

                </div>
             
               )}
                 &nbsp;
               
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Emp Id</td>
                  <td>Emp Name</td>
                  <td>Company</td>
                  <td>Branch</td>
                  <td>Department</td>
                  <td>Designation</td>
                  {change_status &&(
                  <td>Status</td>
                  )}
                  {edit_emp && (
                  <td style={{maxWidth:'500px'}}>Action</td>
                  )}
                  <td>History</td>
               </tr>
               {Employee.map((employess, index) => (
                <tr key={employess.id}>
                  <td>{index + 1}</td>
                  <td>{employess.emp_id}</td>
                  <td>{employess.emp_fname} {employess.emp_lame}</td>
                  <td>{employess.business_name}</td>
                  <td>{employess.branch_name}</td>
                  <td>{employess.department_name}</td>
                  <td>{employess.designation_name}</td>
                  {change_status && (
                  <td><button
                  type="button"
                  className={`btn btn-sm btn-${employess.emp_status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(employess.emp_id)}
                  >
                  {employess.emp_status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                  )}

               
                 <td style={{ maxWidth:'500px' }}>
                  <Link to={`/view-emp-profile/${employess.emp_id}`} className='btn btn-success me-2 btn-sm'>
                   <VisibilityIcon /> 
                  </Link>
                   &nbsp;
                  {edit_emp && (
                    <Link to={`/edit-employee/${employess.emp_id}`} className='btn btn-success me-2 btn-sm'>
                     <EditIcon /> 
                    </Link>
                    )}
                
                 <button className="btn btn-danger btn-sm" onClick={() => deleteemp(employess.emp_id)}>
                 <DeleteIcon /> 
                  </button>
                   </td>
                   <td><Link to={`/emp-salary-history/${employess.emp_id}`} className='btn btn-success me-2 btn-sm'>
                             History
                  </Link></td>
                  
                  
               </tr>
              ))}

            </table>

             )}
                {Employee.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"paging__link--active"} 
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EmployeeDetails;